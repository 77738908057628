import Loadable from '../modifiers/Loadable';
import Resource from '../../tools/resources/types/Resource';
import LoadableResource from '../../tools/resources/types/LoadableResource';
import VersionResource from '../../tools/resources/types/business/version/VersionResource';
import LanguageBundlesResource from '../../tools/resources/types/business/languageBundles/LanguageBundlesResource';
import CvResource from '../../tools/resources/types/business/cv/CvResource';
import UserResource from '../../tools/resources/types/business/users/UserResource';
import ActivitiesResource from '../../tools/resources/types/business/activities/ActivitiesResource';
import SubactivitiesResource from '../../tools/resources/types/business/subactivities/SubactivitiesResource';
import ProjectsResource from '../../tools/resources/types/business/projects/ProjectsResource';
import ProvidersResource from '../../tools/resources/types/business/providers/ProvidersResource';
import PositionsResource from '../../tools/resources/types/business/positions/PositionsResource';
import ProcessedPositionsResource from '../../tools/resources/types/business/positions/processed/ProcessedPositionsResource';
import SkillsResource from '../../tools/resources/types/business/skills/SkillsResource';
import TagsResource from '../../tools/resources/types/business/tags/TagsResource';
import SpecializationsResource from '../../tools/resources/types/business/specializations/SpecializationsResource';
import ProcessedSpecializationsResource from '../../tools/resources/types/business/specializations/processed/ProcessedSpecializationsResource';

import * as actionType from '../actions/resources/resourceActionTypes';
import { LoadVersionResource } from '../actions/resources/load/loadVersionResource';
import { LoadLanguageBundlesResource } from '../actions/resources/load/loadLanguageBundlesResource';
import { LoadCvResource } from '../actions/resources/load/loadCvResource';
import { LoadUserResource } from '../actions/resources/load/loadUserResource';
import { LoadActivitiesResource } from '../actions/resources/load/loadActivitiesResource';
import { LoadSubactivitiesResource } from '../actions/resources/load/loadSubactivitiesResource';
import { LoadProjectsResource } from '../actions/resources/load/loadProjectsResource';
import { LoadProvidersResource } from '../actions/resources/load/loadProvidersResource';
import { LoadPositionsResource } from '../actions/resources/load/loadPositionsResource';
import { LoadSkillsResource } from '../actions/resources/load/loadSkillsResource';
import { LoadTagsResource } from '../actions/resources/load/loadTagsResource';
import { LoadSpecializationsResource } from '../actions/resources/load/loadSpecializationsResource';
import { RequireCvResource } from '../actions/resources/require/requireCvResource';
import { RequireUserResource } from '../actions/resources/require/requireUserResource';
import { RequireActivitiesResource } from '../actions/resources/require/requireActivitiesResource';
import { RequireSubactivitiesResource } from '../actions/resources/require/requireSubactivitiesResource';
import { RequireProjectsResource } from '../actions/resources/require/requireProjectsResource';
import { RequireProvidersResource } from '../actions/resources/require/requireProvidersResource';
import { RequirePositionsResource } from '../actions/resources/require/requirePositionsResource';
import { RequireSkillsResource } from '../actions/resources/require/requireSkillsResource';
import { RequireTagsResource } from '../actions/resources/require/requireTagsResource';
import { RequireSpecializationsResource } from '../actions/resources/require/requireSpecializationsResource';
import { SetCvResource } from '../actions/resources/set/setCvResource';
import { SetUserResource } from '../actions/resources/set/setUserResource';
import { SetActivitiesResource } from '../actions/resources/set/setActivitiesResource';
import { SetSubactivitiesResource } from '../actions/resources/set/setSubactivitiesResource';
import { SetProjectsResource } from '../actions/resources/set/setProjectsResource';
import { SetProvidersResource } from '../actions/resources/set/setProvidersResource';
import { SetPositionsResource } from '../actions/resources/set/setPositionsResource';
import { SetSkillsResource } from '../actions/resources/set/setSkillsResource';
import { SetTagsResource } from '../actions/resources/set/setTagsResource';
import { SetSpecializationsResource } from '../actions/resources/set/setSpecializationsResource';
import { SetLoadingPositionsResource } from '../actions/resources/set/setLoadingPositionsResource';

export type ResourcesStoreEntry<T extends Resource> = Loadable & {
  value: LoadableResource<T>;
  error?: string;
};

export type SettableResourceStoreEntry<
  T extends Resource,
  V extends T,
> = ResourcesStoreEntry<V> & {
  baseValue: LoadableResource<T>;
};

export type RequirableResourceStoreEntry<
  T extends Resource,
  V extends T,
> = SettableResourceStoreEntry<T, V> & {
  required: boolean;
};

interface ResourcesStore {
  version: ResourcesStoreEntry<VersionResource>;
  languageBundles: ResourcesStoreEntry<LanguageBundlesResource>;
  cv: RequirableResourceStoreEntry<CvResource, CvResource>;
  user: RequirableResourceStoreEntry<UserResource, UserResource>;
  activities: RequirableResourceStoreEntry<
    ActivitiesResource,
    ActivitiesResource
  >;
  subactivities: RequirableResourceStoreEntry<
    SubactivitiesResource,
    SubactivitiesResource
  >;
  projects: RequirableResourceStoreEntry<ProjectsResource, ProjectsResource>;
  providers: RequirableResourceStoreEntry<ProvidersResource, ProvidersResource>;
  positions: RequirableResourceStoreEntry<
    PositionsResource,
    PositionsResource | ProcessedPositionsResource
  >;
  skills: RequirableResourceStoreEntry<SkillsResource, SkillsResource>;
  tags: RequirableResourceStoreEntry<TagsResource, TagsResource>;
  specializations: RequirableResourceStoreEntry<
    SpecializationsResource,
    ProcessedSpecializationsResource
  >;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialResourceEntryState: ResourcesStoreEntry<any> = {
  value: null,
  loading: false,
  error: undefined,
};

const initialRequirableResourceEntryState: RequirableResourceStoreEntry<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> = {
  ...initialResourceEntryState,
  baseValue: false,
  required: false,
};

const initialState: ResourcesStore = {
  version: initialResourceEntryState,
  languageBundles: initialResourceEntryState,
  cv: initialRequirableResourceEntryState,
  user: initialRequirableResourceEntryState,
  activities: initialRequirableResourceEntryState,
  subactivities: initialRequirableResourceEntryState,
  projects: initialRequirableResourceEntryState,
  providers: initialRequirableResourceEntryState,
  positions: initialRequirableResourceEntryState,
  skills: initialRequirableResourceEntryState,
  tags: initialRequirableResourceEntryState,
  specializations: initialRequirableResourceEntryState,
};

type ResourcesReducerTypes =
  | LoadVersionResource
  | LoadLanguageBundlesResource
  | RequireCvResource
  | LoadCvResource
  | SetCvResource
  | RequireUserResource
  | LoadUserResource
  | SetUserResource
  | RequireActivitiesResource
  | LoadActivitiesResource
  | SetActivitiesResource
  | RequireSubactivitiesResource
  | LoadSubactivitiesResource
  | SetSubactivitiesResource
  | RequireProjectsResource
  | LoadProjectsResource
  | SetProjectsResource
  | RequireProvidersResource
  | LoadProvidersResource
  | SetProvidersResource
  | RequirePositionsResource
  | LoadPositionsResource
  | SetLoadingPositionsResource
  | SetPositionsResource
  | RequireSkillsResource
  | LoadSkillsResource
  | SetSkillsResource
  | RequireTagsResource
  | LoadTagsResource
  | SetTagsResource
  | RequireSpecializationsResource
  | LoadSpecializationsResource
  | SetSpecializationsResource;

const onRequirementRequirableResourceState: RequirableResourceStoreEntry<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> = {
  baseValue: null,
  value: null,
  loading: false,
  error: undefined,
  required: true,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onLoadingResourceState: ResourcesStoreEntry<any> = {
  value: null,
  loading: true,
  error: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onLoadingRequirableResourceState: RequirableResourceStoreEntry<any, any> =
  {
    baseValue: null,
    value: null,
    loading: true,
    error: undefined,
    required: false,
  };

const onLoadedResourceState = <T extends Resource>(
  value: T,
): ResourcesStoreEntry<T> => ({
  value,
  loading: false,
  error: undefined,
});

const onLoadedRequirableResourceState = <T extends Resource, V extends T>(
  value: T,
): RequirableResourceStoreEntry<T, V> => ({
  baseValue: value,
  value: null,
  loading: true,
  error: undefined,
  required: true,
});

const onAwaitingProcessRequirableResourceState = <
  T extends Resource,
  V extends T,
>(
  value: V,
): RequirableResourceStoreEntry<T, V> => ({
  baseValue: value,
  value,
  loading: true,
  error: undefined,
  required: true,
});

const onProcessedRequirableResourceState = <T extends Resource, V extends T>(
  value: V,
): RequirableResourceStoreEntry<T, V> => ({
  baseValue: null,
  value,
  loading: false,
  error: undefined,
  required: true,
});

const onErrorResourceState = <T extends Resource>(
  errorMsg: string,
): ResourcesStoreEntry<T> => ({
  value: null,
  loading: false,
  error: errorMsg,
});

const onErrorRequirableResourceState = <T extends Resource, V extends T>(
  errorMsg: string,
): RequirableResourceStoreEntry<T, V> => ({
  baseValue: null,
  value: null,
  loading: false,
  error: errorMsg,
  required: false,
});

export const resourcesReducer = (
  state = initialState,
  action: ResourcesReducerTypes,
): ResourcesStore => {
  switch (action.type) {
    case actionType.LOAD_CV_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        cv: onRequirementRequirableResourceState,
      };
    }
    case actionType.LOAD_USER_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        user: onRequirementRequirableResourceState,
      };
    }
    case actionType.LOAD_ACTIVITIES_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        activities: onRequirementRequirableResourceState,
      };
    }
    case actionType.LOAD_SUBACTIVITIES_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        subactivities: onRequirementRequirableResourceState,
      };
    }
    case actionType.LOAD_PROJECTS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        projects: onRequirementRequirableResourceState,
      };
    }
    case actionType.LOAD_PROVIDERS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        providers: onRequirementRequirableResourceState,
      };
    }
    case actionType.LOAD_POSITIONS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        positions: onRequirementRequirableResourceState,
      };
    }
    case actionType.LOAD_SKILLS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        skills: onRequirementRequirableResourceState,
      };
    }
    case actionType.LOAD_TAGS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        tags: onRequirementRequirableResourceState,
      };
    }
    case actionType.LOAD_SPECIALIZATIONS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        specializations: onRequirementRequirableResourceState,
      };
    }
    case actionType.LOAD_VERSION_RESOURCE_REQUEST: {
      return {
        ...state,
        version: onLoadingResourceState,
      };
    }
    case actionType.LOAD_LANGUAGE_BUNDLES_RESOURCE_REQUEST: {
      return {
        ...state,
        languageBundles: onLoadingResourceState,
      };
    }
    case actionType.LOAD_CV_RESOURCE_REQUEST: {
      return {
        ...state,
        cv: onLoadingRequirableResourceState,
      };
    }
    case actionType.LOAD_USER_RESOURCE_REQUEST: {
      return {
        ...state,
        user: onLoadingRequirableResourceState,
      };
    }
    case actionType.LOAD_ACTIVITIES_RESOURCE_REQUEST: {
      return {
        ...state,
        activities: onLoadingRequirableResourceState,
      };
    }
    case actionType.LOAD_SUBACTIVITIES_RESOURCE_REQUEST: {
      return {
        ...state,
        subactivities: onLoadingRequirableResourceState,
      };
    }
    case actionType.LOAD_PROJECTS_RESOURCE_REQUEST: {
      return {
        ...state,
        projects: onLoadingRequirableResourceState,
      };
    }
    case actionType.LOAD_PROVIDERS_RESOURCE_REQUEST: {
      return {
        ...state,
        providers: onLoadingRequirableResourceState,
      };
    }
    case actionType.LOAD_POSITIONS_RESOURCE_REQUEST: {
      return {
        ...state,
        positions: onLoadingRequirableResourceState,
      };
    }
    case actionType.LOAD_SKILLS_RESOURCE_REQUEST: {
      return {
        ...state,
        skills: onLoadingRequirableResourceState,
      };
    }
    case actionType.LOAD_TAGS_RESOURCE_REQUEST: {
      return {
        ...state,
        tags: onLoadingRequirableResourceState,
      };
    }
    case actionType.LOAD_SPECIALIZATIONS_RESOURCE_REQUEST: {
      return {
        ...state,
        specializations: onLoadingRequirableResourceState,
      };
    }
    case actionType.LOAD_VERSION_RESOURCE_RESPONSE: {
      return {
        ...state,
        version: onLoadedResourceState(action.payload),
      };
    }
    case actionType.LOAD_LANGUAGE_BUNDLES_RESOURCE_RESPONSE: {
      return {
        ...state,
        languageBundles: onLoadedResourceState(action.payload),
      };
    }
    case actionType.LOAD_CV_RESOURCE_RESPONSE: {
      return {
        ...state,
        cv: onLoadedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_USER_RESOURCE_RESPONSE: {
      return {
        ...state,
        user: onLoadedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_ACTIVITIES_RESOURCE_RESPONSE: {
      return {
        ...state,
        activities: onLoadedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_SUBACTIVITIES_RESOURCE_RESPONSE: {
      return {
        ...state,
        subactivities: onLoadedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_PROJECTS_RESOURCE_RESPONSE: {
      return {
        ...state,
        projects: onLoadedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_PROVIDERS_RESOURCE_RESPONSE: {
      return {
        ...state,
        providers: onLoadedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_POSITIONS_RESOURCE_RESPONSE: {
      return {
        ...state,
        positions: onLoadedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_SKILLS_RESOURCE_RESPONSE: {
      return {
        ...state,
        skills: onLoadedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_TAGS_RESOURCE_RESPONSE: {
      return {
        ...state,
        tags: onLoadedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_SPECIALIZATIONS_RESOURCE_RESPONSE: {
      return {
        ...state,
        specializations: onLoadedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_POSITIONS_RESOURCE_LOADING_SET: {
      return {
        ...state,
        positions: onAwaitingProcessRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_CV_RESOURCE_SET: {
      return {
        ...state,
        cv: onProcessedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_USER_RESOURCE_SET: {
      return {
        ...state,
        user: onProcessedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_ACTIVITIES_RESOURCE_SET: {
      return {
        ...state,
        activities: onProcessedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_SUBACTIVITIES_RESOURCE_SET: {
      return {
        ...state,
        subactivities: onProcessedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_PROJECTS_RESOURCE_SET: {
      return {
        ...state,
        projects: onProcessedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_PROVIDERS_RESOURCE_SET: {
      return {
        ...state,
        providers: onProcessedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_POSITIONS_RESOURCE_SET: {
      return {
        ...state,
        positions: onProcessedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_SKILLS_RESOURCE_SET: {
      return {
        ...state,
        skills: onProcessedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_TAGS_RESOURCE_SET: {
      return {
        ...state,
        tags: onProcessedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_SPECIALIZATIONS_RESOURCE_SET: {
      return {
        ...state,
        specializations: onProcessedRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_VERSION_RESOURCE_ERROR: {
      return {
        ...state,
        version: onErrorResourceState(action.payload),
      };
    }
    case actionType.LOAD_LANGUAGE_BUNDLES_RESOURCE_ERROR: {
      return {
        ...state,
        languageBundles: onErrorResourceState(action.payload),
      };
    }
    case actionType.LOAD_CV_RESOURCE_ERROR: {
      return {
        ...state,
        cv: onErrorRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_USER_RESOURCE_ERROR: {
      return {
        ...state,
        user: onErrorRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_ACTIVITIES_RESOURCE_ERROR: {
      return {
        ...state,
        activities: onErrorRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_SUBACTIVITIES_RESOURCE_ERROR: {
      return {
        ...state,
        subactivities: onErrorRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_PROJECTS_RESOURCE_ERROR: {
      return {
        ...state,
        projects: onErrorRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_PROVIDERS_RESOURCE_ERROR: {
      return {
        ...state,
        providers: onErrorRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_POSITIONS_RESOURCE_ERROR: {
      return {
        ...state,
        positions: onErrorRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_SKILLS_RESOURCE_ERROR: {
      return {
        ...state,
        skills: onErrorRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_TAGS_RESOURCE_ERROR: {
      return {
        ...state,
        tags: onErrorRequirableResourceState(action.payload),
      };
    }
    case actionType.LOAD_SPECIALIZATIONS_RESOURCE_ERROR: {
      return {
        ...state,
        specializations: onErrorRequirableResourceState(action.payload),
      };
    }
    default:
      return state;
  }
};
