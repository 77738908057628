import ConstructedCvUser from '../ConstructedCvUser';
import ConstructedCvUserPosition from '../../fields/position/constructed/ConstructedCvUserPosition';
import ProcessedPositionsResource from '../../../../../../../tools/resources/types/business/positions/processed/ProcessedPositionsResource';

import updateConstructedUserPositionWithProcessedPositionsResource from '../../fields/position/constructed/operations/updateConstructedUserPositionWithProcessedPositionsResource';

const updateConstructedUserWithProcessedPositionsResource = (
  constructedUser: ConstructedCvUser,
  positionsResource: ProcessedPositionsResource,
): ConstructedCvUser => ({
  ...constructedUser,
  position: updateConstructedUserPositionWithProcessedPositionsResource(
    constructedUser.position as ConstructedCvUserPosition,
    positionsResource.find(
      (position) =>
        position.id ===
        (constructedUser.position as ConstructedCvUserPosition).id,
    ),
  ),
});

export default updateConstructedUserWithProcessedPositionsResource;
