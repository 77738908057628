import React, { useMemo } from 'react';

import { Box, Stack } from '@mui/material';

import useI18n from '../../../../../hooks/internationalization/useI18n';
import useSkillsAppTab from '../../../../../hooks/layout/tabs/useSkillsAppTab';

import TagGroup, {
  DEFAULT_TAG_GROUP_NAME,
} from '../../../../../types/business/tag/group/TagGroup';
import ExperienceGroupId from '../../../../../types/business/experience/group/ExperienceGroupId';
import Experience from '../../../../../types/business/experience/Experience';

import { ComponentProps } from '../../../ComponentProps';
import PaperBackgroundMolecule from '../../background/paper/PaperBackgroundMolecule';
import SkillsBarChartMolecule from '../../charts/bar/skills/SkillsBarChartMolecule';
import SkillListMolecule from '../../lists/skill/SkillListMolecule';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';
import ListChartToggleMolecule from '../../toggles/view/ListChartToggleMolecule';

import { CHART_SKILLS_DEFAULT_TITLE_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';
import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { SPACING } from '../../../../../tools/theme/app/spacing/spacing';

const CHART_DISPLAY_MODE = 'chart';
const LIST_DISPLAY_MODE = 'list';
type DisplayMode = typeof CHART_DISPLAY_MODE | typeof LIST_DISPLAY_MODE;

type SkillsCardMoleculeProps = ComponentProps & {
  id: ExperienceGroupId;
  tagGroup?: TagGroup;
  experience: Experience[];
};

const SkillsCardMolecule: React.FC<SkillsCardMoleculeProps> = ({
  className,
  id,
  tagGroup,
  experience,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);
  const { listCards, switchCardMode } = useSkillsAppTab();

  const displayMode: DisplayMode = useMemo(
    () => (listCards.includes(id) ? LIST_DISPLAY_MODE : CHART_DISPLAY_MODE),
    [id, listCards],
  );

  const findCardHeaderTitle = (): string => {
    if (!tagGroup || tagGroup.name === DEFAULT_TAG_GROUP_NAME) {
      return t(CHART_SKILLS_DEFAULT_TITLE_MAPPING);
    } else {
      if (tagGroup.name) {
        return tagGroup.name;
      }
      return tagGroup.tags.map((tag) => tag.name).join(', ');
    }
  };

  const renderCardHeader = () => (
    <Stack
      className='skills-card__header'
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      spacing={SPACING.big}>
      <TypographyAtom
        className='skills-card__title'
        component='h5'
        variant='h5'>
        {findCardHeaderTitle()}
      </TypographyAtom>
      <ListChartToggleMolecule
        className='skills-card__view-component-toggle'
        checked={displayMode === CHART_DISPLAY_MODE}
        onChange={() => switchCardMode(id)}
      />
    </Stack>
  );

  const renderCardDisplayComponent = () => (
    <Box className='skills-card__view-component-wrapper' paddingY={SPACING.big}>
      {displayMode === CHART_DISPLAY_MODE ? (
        <SkillsBarChartMolecule
          className='skills-card__chart'
          skills={experience}
        />
      ) : (
        <SkillListMolecule
          className='skills-card__list'
          skills={experience}
          isExpanded
          size='medium'
        />
      )}
    </Box>
  );

  return (
    <PaperBackgroundMolecule
      className={`${className} skills-card-${id} skills-card__background`}>
      <Stack className='skills-card__content' direction='column'>
        {renderCardHeader()}
        {renderCardDisplayComponent()}
      </Stack>
    </PaperBackgroundMolecule>
  );
};

export default SkillsCardMolecule;
