import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import useI18n from '../../../../../hooks/internationalization/useI18n';
import useCv from '../../../../../hooks/business/cv/useCv';

import validateActivityPositionsComplete from '../../../../../tools/business/cv/validate/validateActivityPositionsComplete';
import calculatePositionsExperience, {
  PositionExperience,
} from '../../../../../tools/business/cv/calculate/calculatePositionsExperience';
import calculatePositionSpecializationsExperience, {
  PositionSpecializationExperience,
} from '../../../../../tools/business/cv/calculate/calculatePositionSpecializationsExperience';

import { ComponentProps } from '../../../ComponentProps';
import EmptyPlaceholderMolecule from '../../../molecules/placeholders/empty/EmptyPlaceholderMolecule';
import ExperiencePieChartMolecule from '../../../molecules/charts/pie/experience/ExperiencePieChartMolecule';
import LoadingBackgroundMolecule from '../../../molecules/background/loading/LoadingBackgroundMolecule';
import PaperBackgroundMolecule from '../../../molecules/background/paper/PaperBackgroundMolecule';

import { ORGANISMS_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';
import { CV_POSITIONS_EMPTY_MAPPING } from 'src/tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';

type CvPositionsOrganismProps = ComponentProps;

const CvPositionsOrganism: React.FC<CvPositionsOrganismProps> = ({
  className = 'cv-positions-organism',
}) => {
  const { t } = useI18n(ORGANISMS_LOCALE_NAMESPACE);
  const { cvActivities } = useCv();

  const positionSpecializationsExperience = useMemo(() => {
    if (cvActivities && validateActivityPositionsComplete(cvActivities)) {
      const positionsExperience: PositionExperience[] =
        calculatePositionsExperience(cvActivities);

      if (positionsExperience.length > 0) {
        return calculatePositionSpecializationsExperience(positionsExperience);
      }
    }
    return undefined;
  }, [cvActivities]);

  const renderPositions = (
    loadedPositionSpecializationsExperience: PositionSpecializationExperience[],
  ) =>
    loadedPositionSpecializationsExperience.length > 0 ? (
      <PaperBackgroundMolecule className='cv-positions__background'>
        <ExperiencePieChartMolecule
          className='cv-positions__chart'
          positionSpecializationsExperience={
            loadedPositionSpecializationsExperience
          }
        />
      </PaperBackgroundMolecule>
    ) : (
      <EmptyPlaceholderMolecule
        className='cv-positions__no-positions'
        text={t(CV_POSITIONS_EMPTY_MAPPING)}
        width='100%'
        height='auto'
        minHeight='200px'
      />
    );

  const renderLoading = () => (
    <LoadingBackgroundMolecule
      className='cv-positions__loading'
      height='auto'
    />
  );

  return (
    <Box
      className={`${className} cv-positions`}
      width='100%'
      display='flex'
      flexGrow={1}
      alignItems='stretch'>
      {positionSpecializationsExperience
        ? renderPositions(positionSpecializationsExperience)
        : renderLoading()}
    </Box>
  );
};

export default CvPositionsOrganism;
