import React from 'react';

import { Box, Stack } from '@mui/material';

import useAppThemeUtils from '../../../../../../hooks/layout/theme/useAppThemeUtils';
import useTimeDateI18n from '../../../../../../hooks/internationalization/useTimeDateI18n';
import useAppTheme from '../../../../../../hooks/layout/theme/useAppTheme';

import NonThemedHexColorCommon from '../../../../../../types/common/media/color/hex/variants/NonThemedHexColorCommon';
import SkillName from '../../../../../../types/business/skill/SkillName';
import Experience from '../../../../../../types/business/experience/Experience';

import isHexColorCommonThemed from '../../../../../../types/common/media/color/hex/guards/isHexColorCommonThemed';
import fromDurationToMomentDuration from '../../../../../../tools/timedate/duration/converters/fromDurationToMomentDuration';
import fromMomentDurationToDuration from '../../../../../../tools/timedate/duration/converters/fromMomentDurationToDuration';

import { ComponentProps } from '../../../../ComponentProps';
import BarChartAtom from '../../../../atoms/charts/bar/BarChartAtom';
import SkillListYAxisLegendSkillsBarChartMolecule from './legend/axis/y/SkillListYAxisLegendSkillsBarChartMolecule';

const SKILLS_CHART_NAME_KEY = 'name';
const SKILLS_CHART_DATA_KEY = 'experienceInDays';

type SkillsDatasetEntry = {
  [SKILLS_CHART_NAME_KEY]: SkillName;
  [SKILLS_CHART_DATA_KEY]: number;
};

const CHART_BAR_HEIGHT = 40;
// Equals to tick size
const CHART_LEFT_MARGIN = 6;
const CHART_NO_MARGIN = 0;

type SkillsBarChartMoleculeProps = ComponentProps & {
  skills: Experience[];
};

const SkillsBarChartMolecule: React.FC<SkillsBarChartMoleculeProps> = ({
  className,
  skills,
}) => {
  const { appTheme } = useAppTheme();
  const { withThemeMode } = useAppThemeUtils();
  const { dui } = useTimeDateI18n();

  const transformExperienceToDataset = (
    totalExperience: Experience[],
  ): SkillsDatasetEntry[] =>
    totalExperience.map((skillExperience) => ({
      [SKILLS_CHART_NAME_KEY]: skillExperience.name,
      seriesId: skillExperience.id,
      [SKILLS_CHART_DATA_KEY]: fromDurationToMomentDuration(
        skillExperience.experience,
      ).asDays(),
    }));

  const transformExperienceToPalette = (
    totalExperience: Experience[],
  ): NonThemedHexColorCommon[] =>
    totalExperience.map((skillExperience) => {
      const experienceColor = skillExperience.color;
      if (!experienceColor) {
        return appTheme.palette.primary.dark;
      }

      if (isHexColorCommonThemed(experienceColor)) {
        return withThemeMode(experienceColor.light, experienceColor.dark);
      } else {
        return experienceColor;
      }
    });

  const experienceValueFormatter = (experienceInDays: number | null): string =>
    dui(
      fromMomentDurationToDuration(
        fromDurationToMomentDuration({
          years: 0,
          months: 0,
          days: experienceInDays ?? 0,
        }),
      ),
    );

  return (
    <Stack
      className={`${className} skills-bar-chart__container`}
      direction='row'
      // Top padding to align the single-item chart and list view
      paddingTop='3.06px'>
      <SkillListYAxisLegendSkillsBarChartMolecule
        className='skills-bar-chart__y-axis'
        skills={skills}
      />
      <Box
        className='skills-bar-chart__wrapper'
        width={{ xs: '70%', sm: '60%' }}>
        <BarChartAtom
          className='skills-bar-chart'
          disableAxisListener
          dataset={transformExperienceToDataset(skills)}
          yAxis={[
            {
              scaleType: 'band',
              tickPlacement: 'start',
              dataKey: SKILLS_CHART_NAME_KEY,
              colorMap: {
                type: 'ordinal',
                colors: transformExperienceToPalette(skills),
              },
            },
          ]}
          firstTickHidden
          xAxisHidden
          yAxisLabelsHidden
          series={[
            {
              dataKey: SKILLS_CHART_DATA_KEY,
              valueFormatter: experienceValueFormatter,
            },
          ]}
          layout='horizontal'
          height={skills.length * CHART_BAR_HEIGHT}
          margin={{
            top: CHART_NO_MARGIN,
            right: CHART_NO_MARGIN,
            bottom: CHART_NO_MARGIN,
            left: CHART_LEFT_MARGIN,
          }}
        />
      </Box>
    </Stack>
  );
};

export default SkillsBarChartMolecule;
