interface UseWindowActions {
  scroll(top?: number, left?: number): void;
  scrollSmoothly(top?: number, left?: number): void;
}

export const useWindowActions = (): UseWindowActions => {
  const scroll = (top = 0, left = 0) => {
    window.scrollTo({ top, left });
  };

  const scrollSmoothly = (top = 0, left = 0) => {
    window.scrollTo({ top, left, behavior: 'smooth' });
  };

  return { scroll, scrollSmoothly };
};

export default useWindowActions;
