import setValue, { SetValue } from '../../setValue';

import ProvidersResource from '../../../../tools/resources/types/business/providers/ProvidersResource';

import { LOAD_PROVIDERS_RESOURCE_SET } from '../resourceActionTypes';

export type SetProvidersResource = SetValue<
  typeof LOAD_PROVIDERS_RESOURCE_SET,
  ProvidersResource
>;

const setProvidersResource = (resource: ProvidersResource) =>
  setValue<SetProvidersResource>(LOAD_PROVIDERS_RESOURCE_SET, resource);

export default setProvidersResource;
