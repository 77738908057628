import CvActivity from '../../../../types/business/cv/fields/activity/CvActivity';

import deepClone from '../../../utils/deepClone';
import isPresentDate from '../../../timedate/date/guards/isPresentDate';
import compareDates from '../../../timedate/date/comparators/compareDates';

const sortActivities = (activities: CvActivity[]) =>
  deepClone(activities)
    .sort((a, b) => {
      // Compare start dates
      const startPeriodComparison = compareDates(
        a.period.start,
        b.period.start,
      );
      if (startPeriodComparison !== 0) {
        return startPeriodComparison;
      }

      // Compare end dates
      if (isPresentDate(a.period.end)) {
        return 1;
      }
      if (isPresentDate(b.period.end)) {
        return -1;
      }
      return compareDates(a.period.end, b.period.end);
    })
    .reverse();

export default sortActivities;
