import { LocaleNamespace } from '../LocaleNamespace';
import { LOCALE_NAMESPACE_NAME } from '../LocaleNamespaceName';
import {
  NOT_FOUND_FZF_MAPPING,
  OrganismsLocaleNamespaceMappings,
} from './OrganismsLocaleNamespaceMappings';

export type OrganismsLocaleNamespace = {
  cv: {
    activities: {
      name: string;
      empty: string;
    };
    skills: {
      name: string;
      empty: string;
    };
    positions: {
      name: string;
      empty: string;
    };
  };
  notFound: {
    title: string;
    header: string;
    fzf: string;
    back: string;
  };
  footer: {
    copyright: string;
    creator: {
      createdBy: string;
      createdByCompact: string;
      creatorName: string;
    };
    links: {
      gitlab: string;
    };
  };
};

export const ORGANISMS_LOCALE_NAMESPACE: LocaleNamespace<OrganismsLocaleNamespaceMappings> =
  {
    name: LOCALE_NAMESPACE_NAME.ORGANISMS,
    mappings: NOT_FOUND_FZF_MAPPING,
  };
