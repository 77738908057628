import setValue, { SetValue } from '../../setValue';

import CvResource from '../../../../tools/resources/types/business/cv/CvResource';

import { LOAD_CV_RESOURCE_SET } from '../resourceActionTypes';

export type SetCvResource = SetValue<typeof LOAD_CV_RESOURCE_SET, CvResource>;

const setCvResource = (resource: CvResource) =>
  setValue<SetCvResource>(LOAD_CV_RESOURCE_SET, resource);

export default setCvResource;
