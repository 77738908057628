import setValue, { SetValue } from '../../../../setValue';

import ActivitiesAppTabExpandedDescriptionCards from '../../../../../../types/app/tabs/state/fields/activities/cards/ActivitiesAppTabExpandedDescriptionCards';

import { SET_ACTIVITIES_APP_TAB_EXPANDED_DESCRIPTION_CARDS } from '../../../appActionTypes';

export type SetActivitiesAppTabExpandedDescriptionCards = SetValue<
  typeof SET_ACTIVITIES_APP_TAB_EXPANDED_DESCRIPTION_CARDS,
  ActivitiesAppTabExpandedDescriptionCards
>;

const setActivitiesAppTabExpandedDescriptionCards = (
  expandedDescriptionCards: ActivitiesAppTabExpandedDescriptionCards,
) =>
  setValue<SetActivitiesAppTabExpandedDescriptionCards>(
    SET_ACTIVITIES_APP_TAB_EXPANDED_DESCRIPTION_CARDS,
    expandedDescriptionCards,
  );

export default setActivitiesAppTabExpandedDescriptionCards;
