import { Dispatch } from 'redux';

type Type = string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Payload = any;

export type SetValue<T extends Type, P extends Payload> = {
  type: T;
  payload: P;
};

export type SetOptionalValue<T extends Type, P extends Payload> = SetValue<
  T,
  P | undefined
>;

const dispatchValue =
  <T extends Type, P extends Payload>(type: T, payload: P) =>
  (dispatch: Dispatch<SetValue<T, P>>) => {
    dispatch({ type, payload });
  };

const setValue = <A extends SetValue<Type, Payload>>(
  type: A['type'],
  payload: A['payload'],
) => dispatchValue<A['type'], A['payload']>(type, payload);

export default setValue;
