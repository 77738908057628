import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setAppTabScroll from '../../../storage/actions/app/set/tabs/any/scroll/setAppTabScroll';

import useWindowActions from '../window/useWindowActions';

import AppTabKey from '../../../types/app/tabs/key/AppTabKey';

import { INITIAL_APP_TAB_SCROLL } from '../../../types/app/tabs/state/fields/any/scroll/AppTabScroll';

interface UseAppTabsScroll {
  saveAppTabScroll(tabKey: AppTabKey): void;

  applyAppTabScroll(tabKey: AppTabKey): void;

  restoreAppTabScroll(): void;
}

const useAppTabsScroll = (
  anchorRef: React.RefObject<HTMLAnchorElement>,
  anchorScrollCorrection = -100,
): UseAppTabsScroll => {
  const dispatch = useRootDispatch();
  const { tabs } = useSelector((state: RootState) => state.appReducer);

  const { scroll, scrollSmoothly } = useWindowActions();

  const anchorScroll = anchorRef.current?.offsetTop ?? INITIAL_APP_TAB_SCROLL;

  const saveAppTabScroll = useCallback(
    (tabKey: AppTabKey) => {
      dispatch(setAppTabScroll(tabKey, window.scrollY));
    },
    [dispatch],
  );

  const applyAppTabScroll = (tabKey: AppTabKey) => {
    const previousAppTabScroll = tabs[tabs.previous].scroll;
    const currentAppTabScroll = tabs[tabKey].scroll;

    const isPreviousScrollLessThanAnchor =
      previousAppTabScroll < anchorScroll + anchorScrollCorrection;
    const isCurrentScrollMoreThanAnchor = currentAppTabScroll > anchorScroll;

    if (isPreviousScrollLessThanAnchor) {
      scroll(previousAppTabScroll);
    } else if (isCurrentScrollMoreThanAnchor) {
      scroll(currentAppTabScroll);
    } else {
      scroll(anchorScroll);
    }
  };

  const restoreAppTabScroll = () => {
    scrollSmoothly(anchorScroll);
  };

  return {
    saveAppTabScroll,
    applyAppTabScroll,
    restoreAppTabScroll,
  };
};

export default useAppTabsScroll;
