import setValue, { SetValue } from '../../setValue';

import TagsResource from '../../../../tools/resources/types/business/tags/TagsResource';

import { LOAD_TAGS_RESOURCE_SET } from '../resourceActionTypes';

export type SetTagsResource = SetValue<
  typeof LOAD_TAGS_RESOURCE_SET,
  TagsResource
>;

const setTagsResource = (resource: TagsResource) =>
  setValue<SetTagsResource>(LOAD_TAGS_RESOURCE_SET, resource);

export default setTagsResource;
