import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { STORE } from './storage/store/store';
import './tools/internationalization/i18n/i18n';

import AppEffects from './elements/app/effect/AppEffects';
import AppLogger from './elements/app/logger/AppLogger';
import AppTheme from './elements/app/theme/AppTheme';
import CvPage from './elements/pages/cv/CvPage';
import CvActivitiesSubpage from './elements/pages/cv/subpages/activities/CvActivitiesSubpage';
import CvPositionsSubpage from './elements/pages/cv/subpages/positions/CvPositionsSubpage';
import CvSkillsSubpage from './elements/pages/cv/subpages/skills/CvSkillsSubpage';
import NotFoundPage from './elements/pages/notFound/NotFoundPage';

import { INTERNAL_NAVIGATION_SCHEME } from './tools/navigation/internalNavigationScheme';

import './assets/fonts/fonts';

import './index.css';
import './mui-dynamic-elements.css';

import getI18nLanguage from './tools/internationalization/i18n/utils/getI18nLanguage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={STORE}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={getI18nLanguage()}>
        <BrowserRouter>
          <AppEffects>
            <AppLogger>
              <AppTheme>
                <Routes>
                  <Route
                    path={INTERNAL_NAVIGATION_SCHEME.root.path}
                    element={<Outlet />}>
                    <Route
                      path={INTERNAL_NAVIGATION_SCHEME.root.path}
                      element={<CvPage />}>
                      <Route
                        index
                        element={
                          <Navigate
                            to={INTERNAL_NAVIGATION_SCHEME.activities.path}
                            replace
                          />
                        }
                      />
                      <Route
                        path={INTERNAL_NAVIGATION_SCHEME.activities.path}
                        element={<CvActivitiesSubpage />}
                      />
                      <Route
                        path={INTERNAL_NAVIGATION_SCHEME.skills.path}
                        element={<CvSkillsSubpage />}
                      />
                      <Route
                        path={INTERNAL_NAVIGATION_SCHEME.positions.path}
                        element={<CvPositionsSubpage />}
                      />
                    </Route>
                    <Route
                      path={INTERNAL_NAVIGATION_SCHEME.notFound.rule}
                      element={<NotFoundPage />}
                    />
                  </Route>
                </Routes>
              </AppTheme>
            </AppLogger>
          </AppEffects>
        </BrowserRouter>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>,
);
