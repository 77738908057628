import setValue, { SetValue } from '../../setValue';

import SkillsResource from '../../../../tools/resources/types/business/skills/SkillsResource';

import { LOAD_SKILLS_RESOURCE_SET } from '../resourceActionTypes';

export type SetSkillsResource = SetValue<
  typeof LOAD_SKILLS_RESOURCE_SET,
  SkillsResource
>;

const setSkillsResource = (resource: SkillsResource) =>
  setValue<SetSkillsResource>(LOAD_SKILLS_RESOURCE_SET, resource);

export default setSkillsResource;
