type FontWeight = {
  medium: string;
  well: string;
  bold: string;
};

export const FONT_WEIGHT: FontWeight = {
  medium: '400',
  well: '500',
  bold: '700',
};
