import React from 'react';

import { Box, Stack } from '@mui/material';

import Experience from '../../../../../../../../../types/business/experience/Experience';

import { ComponentProps } from '../../../../../../../ComponentProps';
import SkillChipYAxisLegendSkillsBarChartMolecule from './SkillChipYAxisLegendSkillsBarChartMolecule';

type SkillListYAxisLegendSkillsBarChartMoleculeProps = ComponentProps & {
  skills: Experience[];
};

const SkillListYAxisLegendSkillsBarChartMolecule: React.FC<
  SkillListYAxisLegendSkillsBarChartMoleculeProps
> = ({ className, skills }) => {
  const renderChips = () =>
    skills.map((skill) => (
      <Box
        className='skills-bar-chart-y-axis-skill-list__item'
        component='li'
        key={skill.id}
        maxWidth='calc(100%)'
        sx={{ listStyle: 'none' }}>
        <SkillChipYAxisLegendSkillsBarChartMolecule
          className='skills-bar-chart-y-axis-skill-list__item-chip'
          skill={{
            id: skill.id,
            name: skill.name,
            experience: skill.experience,
            avatar: skill.avatar,
          }}
        />
      </Box>
    ));

  return (
    <Stack
      className={`${className} skills-bar-chart-y-axis-skill-list`}
      component='ul'
      padding={0}
      margin={0}
      width='30%'
      minWidth='150px'
      direction='column'
      alignItems='flex-end'>
      {renderChips()}
    </Stack>
  );
};

export default SkillListYAxisLegendSkillsBarChartMolecule;
