import React from 'react';

import { Box } from '@mui/material';

import { ComponentProps } from '../../../ComponentProps';
import ThemeModeToggleMolecule from '../../../molecules/toggles/theme/ThemeModeToggleMolecule';

import { SPACING } from '../../../../../tools/theme/app/spacing/spacing';

type WideHeaderOrganismProps = ComponentProps & {
  height?: string;
};

const WideHeaderOrganism: React.FC<WideHeaderOrganismProps> = ({
  className = 'wide-header-organism',
  height = 'auto',
}) => (
  <Box
    className={`${className} wide-header`}
    component='header'
    width='100%'
    height={height}
    display='flex'
    flexDirection='row'
    alignItems='center'
    justifyContent='flex-end'
    paddingY={SPACING.little}
    paddingX={SPACING.little}>
    <ThemeModeToggleMolecule className='header__theme-toggle' />
  </Box>
);

export default WideHeaderOrganism;
