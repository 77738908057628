import UserFirstName from '../../../../types/business/user/UserFirstName';
import UserMiddleName from '../../../../types/business/user/UserMiddleName';
import UserLastName from '../../../../types/business/user/UserLastName';
import UserAge from '../../../../types/business/user/UserAge';

const EMPTY_STRING = '';

const buildUserNameAge = (
  firstName: UserFirstName,
  middleName?: UserMiddleName,
  lastName?: UserLastName,
  age?: UserAge,
) =>
  firstName
    .concat(middleName ? ` ${middleName}` : EMPTY_STRING)
    .concat(lastName ? ` ${lastName}` : EMPTY_STRING)
    .concat(age ? `, ${age}` : EMPTY_STRING);

export default buildUserNameAge;
