import ConstructedCvActivityPosition from '../ConstructedCvActivityPosition';
import ProcessedPositionResource from '../../../../../../../../../tools/resources/types/business/positions/processed/ProcessedPositionResource';

const updateConstructedActivityPositionWithProcessedPositionsResource = (
  constructedPosition: ConstructedCvActivityPosition,
  positionResource?: ProcessedPositionResource,
): ConstructedCvActivityPosition => {
  if (positionResource) {
    return {
      ...constructedPosition,
      color: positionResource.color,
    };
  }
  return constructedPosition;
};

export default updateConstructedActivityPositionWithProcessedPositionsResource;
