import setValue, { SetValue } from '../../setValue';

import ProcessedPositionsResource from '../../../../tools/resources/types/business/positions/processed/ProcessedPositionsResource';

import { LOAD_POSITIONS_RESOURCE_SET } from '../resourceActionTypes';

export type SetPositionsResource = SetValue<
  typeof LOAD_POSITIONS_RESOURCE_SET,
  ProcessedPositionsResource
>;

const setPositionsResource = (resource: ProcessedPositionsResource) =>
  setValue<SetPositionsResource>(LOAD_POSITIONS_RESOURCE_SET, resource);

export default setPositionsResource;
