import SpecializationsResource from '../../../types/business/specializations/SpecializationsResource';
import ProcessedSpecializationsResource from '../../../types/business/specializations/processed/ProcessedSpecializationsResource';
import ThemedHexPaletteCommon from '../../../../../types/common/media/palette/hex/variants/ThemedHexPaletteCommon';

import ResourceProcessResult from '../../types/result/ResourceProcessResult';
import buildSuccessfulResourceProcessResult from '../../types/result/builders/buildSuccessfulResourceProcessResult';

import processResourceWithPalette from '../../common/processResourceWithPalette';

const processSpecializationsResourceWithPalette = (
  specializationsResource: SpecializationsResource,
  palette: ThemedHexPaletteCommon,
): ResourceProcessResult<ProcessedSpecializationsResource> =>
  buildSuccessfulResourceProcessResult<ProcessedSpecializationsResource>(
    processResourceWithPalette(specializationsResource, palette),
  );

export default processSpecializationsResourceWithPalette;
