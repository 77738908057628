import NumberDate, {
  DATE_DEFAULT_DAY,
  DATE_DEFAULT_MONTH,
  DATE_DEFAULT_YEAR,
} from '../NumberDate';

const buildNumberDate = (
  year?: number,
  month?: number,
  day?: number,
): NumberDate => ({
  year: year ?? DATE_DEFAULT_YEAR,
  month: month ?? DATE_DEFAULT_MONTH,
  day: day ?? DATE_DEFAULT_DAY,
});
export default buildNumberDate;
