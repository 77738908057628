import React from 'react';

import { Stack } from '@mui/material';

import { PositionSpecializationExperience } from '../../../../../../../tools/business/cv/calculate/calculatePositionSpecializationsExperience';

import { ComponentProps } from '../../../../../ComponentProps';
import ExperienceListLegendExperiencePieChartMolecule, {
  SpecializationOrPositionExperience,
} from './ExperienceListLegendExperiencePieChartMolecule';

import { SPACING } from '../../../../../../../tools/theme/app/spacing/spacing';

type LegendExperiencePieChartMoleculeProps = ComponentProps & {
  positionSpecializationsExperience: PositionSpecializationExperience[];
};

const LegendExperiencePieChartMolecule: React.FC<
  LegendExperiencePieChartMoleculeProps
> = ({ className, positionSpecializationsExperience }) => {
  const renderSpecializationsExperience = (
    experiences: PositionSpecializationExperience[],
  ): SpecializationOrPositionExperience[] =>
    experiences.map((experience) => ({
      id: experience.specialization.id,
      label: experience.specialization.name,
      experience: experience.experience,
      color: experience.specialization.color,
    }));

  const renderPositionsExperience = (
    experiences: PositionSpecializationExperience[],
  ): SpecializationOrPositionExperience[] =>
    experiences.flatMap((experience) =>
      experience.positionExperiences.map((positionExperience) => ({
        id: positionExperience.position.id,
        label: positionExperience.position.name,
        experience: positionExperience.experience,
        color: positionExperience.position.color,
      })),
    );

  return (
    <Stack
      className={`${className} experience-pie-chart-legend`}
      width='100%'
      direction='column'
      spacing={SPACING.huge}>
      <ExperienceListLegendExperiencePieChartMolecule
        className='experience-pie-chart-legend__specializations'
        specializationOrPositionExperiences={renderSpecializationsExperience(
          positionSpecializationsExperience,
        )}
      />
      <ExperienceListLegendExperiencePieChartMolecule
        className='experience-pie-chart-legend__positions'
        specializationOrPositionExperiences={renderPositionsExperience(
          positionSpecializationsExperience,
        )}
      />
    </Stack>
  );
};

export default LegendExperiencePieChartMolecule;
