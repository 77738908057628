import React from 'react';

import { Box, Stack } from '@mui/material';

import NavEndpoint from '../../../../../tools/navigation/endpoints/NavEndpoint';
import buildUrlFromEndpoint from '../../../../../tools/navigation/utils/buildUrlFromEndpoint';
import ImageCommon from '../../../../../types/common/media/image/ImageCommon';
import ImageCommonSource from '../../../../../types/common/media/image/fields/source/ImageCommonSource';

import { ComponentProps } from '../../../ComponentProps';
import AvatarLinkMolecule from '../../links/avatar/AvatarLinkMolecule';

import { SPACING } from '../../../../../tools/theme/app/spacing/spacing';

type ImageCommonWithUndefinedSource = Omit<ImageCommon, 'src' | 'alt'> & {
  src?: ImageCommonSource;
  alt: string;
};

export type NavListMoleculeItem = {
  image: ImageCommonWithUndefinedSource;
  to: NavEndpoint;
};

type NavListMoleculeProps = ComponentProps & {
  navs: NavListMoleculeItem[];
  size?: 'small' | 'medium';
  badge?: boolean;
};

const NavListMolecule: React.FC<NavListMoleculeProps> = ({
  className,
  navs,
  size = 'small',
  badge = false,
}) => {
  const renderNavs = () => {
    const uniqueNavs = new Map<string, NavListMoleculeItem>();
    navs.forEach((nav) => {
      uniqueNavs.set(buildUrlFromEndpoint(nav.to), nav);
    });

    return Array.from(uniqueNavs.entries()).map((navEntry) => (
      <Box
        className='nav-list__item'
        component='li'
        key={navEntry[0]}
        sx={{ listStyle: 'none' }}>
        <AvatarLinkMolecule
          className='nav-list__item-avatar-link'
          image={navEntry[1].image}
          to={navEntry[1].to}
          size={size}
          badge={badge}
        />
      </Box>
    ));
  };

  return (
    <Stack
      className={`${className} nav-list`}
      direction='row'
      component='ul'
      padding={SPACING.none}
      margin={SPACING.none}
      spacing={size === 'small' ? SPACING.little : SPACING.medium}>
      {renderNavs()}
    </Stack>
  );
};

export default NavListMolecule;
