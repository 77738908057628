import setValue, { SetValue } from '../../../setValue';

import AppTabKey from '../../../../../types/app/tabs/key/AppTabKey';

import { SET_APP_TAB } from '../../appActionTypes';

export type SetAppTab = SetValue<typeof SET_APP_TAB, AppTabKey>;

const setAppTab = (tabKey: AppTabKey) =>
  setValue<SetAppTab>(SET_APP_TAB, tabKey);

export default setAppTab;
