import React, { useEffect } from 'react';

import { Tabs, TabsProps } from '@mui/material';

import useAppTheme from '../../../../../hooks/layout/theme/useAppTheme';
import useWindowBreakpoint, {
  Breakpoint,
} from '../../../../../hooks/layout/window/useWindowBreakpoint';

import { BORDER_RADIUS } from '../../../../../tools/theme/app/rounding/borderRadius';

type TabGroupAtomProps = TabsProps & {
  scrollableBreakpoint?: Breakpoint;
  hideIndicator?: boolean;
  hideScrollButtonsInEndPositions?: boolean;
};

const TabGroupAtom: React.FC<TabGroupAtomProps> = ({
  className,
  value,
  scrollableBreakpoint,
  variant,
  scrollButtons,
  allowScrollButtonsMobile,
  hideScrollButtonsInEndPositions,
  hideIndicator,
  sx,
  onChange,
  children,
}) => {
  const { appTheme } = useAppTheme();
  const breakpoint = useWindowBreakpoint();

  const [isScrollable, setIsScrollable] = React.useState(
    variant === 'scrollable',
  );

  useEffect(() => {
    setIsScrollable(breakpoint === scrollableBreakpoint);
  }, [breakpoint, scrollableBreakpoint]);

  const customSx = {
    borderRadius: BORDER_RADIUS.paper,
    '&.MuiTabs-root': {
      '& .MuiTabs-scroller': {
        flex: 'unset',
        marginX:
          isScrollable && hideScrollButtonsInEndPositions ? '-40px' : '0',
      },
      '& .MuiTabScrollButton-root': {
        zIndex: appTheme.zIndex.appBar + 1,
        backgroundColor: appTheme.palette.background.default,
        opacity: 1,
        easing: 'ease',
        transition: appTheme.transitions.create('opacity', {
          duration: appTheme.transitions.duration.shorter,
        }),
        '&.Mui-disabled': {
          opacity: hideScrollButtonsInEndPositions ? 0 : 1,
        },
        '&:first-of-type': {
          borderTopLeftRadius: BORDER_RADIUS.paper,
          borderBottomLeftRadius: BORDER_RADIUS.paper,
        },
        '&:last-of-type': {
          borderTopRightRadius: BORDER_RADIUS.paper,
          borderBottomRightRadius: BORDER_RADIUS.paper,
        },
      },
      '& .MuiTab-root': {
        '&:first-of-type': {
          borderTopLeftRadius: BORDER_RADIUS.paper,
          borderBottomLeftRadius: BORDER_RADIUS.paper,
        },
        '&:last-of-type': {
          borderTopRightRadius: BORDER_RADIUS.paper,
          borderBottomRightRadius: BORDER_RADIUS.paper,
        },
      },
    },
    '& .MuiTabs-indicator': {
      display: hideIndicator ? 'none' : 'block',
    },
    ...sx,
  };

  return (
    <Tabs
      className={className}
      value={value}
      variant={isScrollable ? 'scrollable' : variant}
      scrollButtons={isScrollable ? true : scrollButtons}
      allowScrollButtonsMobile={allowScrollButtonsMobile}
      onChange={onChange}
      sx={customSx}>
      {children}
    </Tabs>
  );
};

export default TabGroupAtom;
