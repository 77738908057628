import React, { ReactElement } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { EmailOutlined } from '@mui/icons-material';

import useI18n from '../../../../../hooks/internationalization/useI18n';
import useAppThemeUtils from '../../../../../hooks/layout/theme/useAppThemeUtils';
import useAssetsResources from '../../../../../hooks/resources/useAssetsResources';

import ImageCommon from '../../../../../types/common/media/image/ImageCommon';
import UserContact from '../../../../../types/business/user/UserContact';
import ContactsUserPreference, {
  ACTIVE_CONTACTS_USER_PREFERENCE,
  LIMITED_CONTACTS_USER_PREFERENCE,
  REGULAR_CONTACTS_USER_PREFERENCE,
} from '../../../../../types/business/user/preferences/ContactsUserPreference';
import SocialContactType, {
  EMAIL_SOCIAL_CONTACT_TYPE,
  TELEGRAM_SOCIAL_CONTACT_TYPE,
} from '../../../../../types/business/social/contact/SocialContactType';
import isImageCommonThemed from '../../../../../types/common/media/image/guards/isImageCommonThemed';

import DialogMoleculeProps from '../DialogMoleculeProps';
import ButtonAtom from '../../../atoms/buttons/regular/ButtonAtom';
import AvatarAtom from '../../../atoms/avatars/AvatarAtom';
import CopyableTextMolecule from '../../text/copyable/CopyableTextMolecule';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import {
  DIALOG_CONTACT_CLOSE_MAPPING,
  DIALOG_CONTACT_REGULAR_ALERT_TEXT_MAPPING,
  DIALOG_CONTACT_TITLE_MAPPING,
  DIALOG_CONTACT_WARNING_ALERT_TEXT_MAPPING,
  DIALOG_CONTACT_WARNING_ALERT_TITLE_MAPPING,
} from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';
import { BORDER_RADIUS } from '../../../../../tools/theme/app/rounding/borderRadius';
import { SPACING } from '../../../../../tools/theme/app/spacing/spacing';
import { FONT_WEIGHT } from '../../../../../tools/theme/app/font/fontWeight';

type ContactDialogMoleculeProps = DialogMoleculeProps & {
  contacts: UserContact[];
  preference?: ContactsUserPreference;
};

const ContactDialogMolecule: React.FC<ContactDialogMoleculeProps> = ({
  className,
  contacts,
  preference,
  open,
  handleClose,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);
  const { withThemeMode } = useAppThemeUtils();
  const { telegramLogo } = useAssetsResources();

  const contactItemAvatarSx = {
    width: 24,
    height: 24,
  };

  const buildContactItemAvatar = (avatar: Required<ImageCommon>) =>
    isImageCommonThemed(avatar) ? (
      withThemeMode(
        <AvatarAtom
          src={avatar.src.light}
          alt={avatar.alt}
          shape={avatar.shape}
          sx={contactItemAvatarSx}
        />,
        <AvatarAtom
          src={avatar.src.dark}
          alt={avatar.alt}
          shape={avatar.shape}
          sx={contactItemAvatarSx}
        />,
      )
    ) : (
      <AvatarAtom
        src={avatar.src}
        alt={avatar.alt}
        shape={avatar.shape}
        sx={contactItemAvatarSx}
      />
    );

  const getContactItemIcon = (type: SocialContactType) => {
    switch (type) {
      case EMAIL_SOCIAL_CONTACT_TYPE:
        return <EmailOutlined />;
      case TELEGRAM_SOCIAL_CONTACT_TYPE:
        return buildContactItemAvatar(telegramLogo);
      default:
        return undefined;
    }
  };

  const renderContactListItems = () => {
    const contactElements: ReactElement[] = [];

    contacts.forEach((contact) => {
      contactElements.push(
        <Box
          className='contact-list__item'
          component='li'
          key={`${contact.type}-${contact.value}`}
          sx={{ listStyle: 'none' }}>
          <CopyableTextMolecule
            className='contact-list__item-text'
            icon={getContactItemIcon(contact.type)}
            variant='body1'>
            {contact.value}
          </CopyableTextMolecule>
        </Box>,
      );
    });

    return contactElements;
  };

  const renderContactList = () => (
    <Stack
      className='contact-dialog__contact-list'
      width='100%'
      component='ul'
      padding={SPACING.none}
      margin={SPACING.none}
      spacing={SPACING.tiny}
      direction='column'>
      {renderContactListItems()}
    </Stack>
  );

  const renderAlert = () => {
    switch (preference) {
      case ACTIVE_CONTACTS_USER_PREFERENCE:
        return (
          <Alert
            className='contact-dialog__alert-success'
            severity='success'
            sx={{ borderRadius: BORDER_RADIUS.paper }}>
            {t(DIALOG_CONTACT_REGULAR_ALERT_TEXT_MAPPING)}
          </Alert>
        );
      case LIMITED_CONTACTS_USER_PREFERENCE:
        return (
          <Alert
            className='contact-dialog__alert-warning'
            severity='warning'
            sx={{ borderRadius: BORDER_RADIUS.paper }}>
            <AlertTitle>
              {t(DIALOG_CONTACT_WARNING_ALERT_TITLE_MAPPING)}
            </AlertTitle>
            {t(DIALOG_CONTACT_WARNING_ALERT_TEXT_MAPPING)}
          </Alert>
        );
      case REGULAR_CONTACTS_USER_PREFERENCE:
      default:
        return null;
    }
  };

  const renderDialogContent = () => (
    <Stack
      className='contact-dialog__content'
      direction='column'
      spacing={SPACING.big}>
      {renderAlert()}
      {renderContactList()}
    </Stack>
  );

  return (
    <Dialog
      className={`${className} contact-dialog`}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='xs'
      scroll='paper'
      PaperProps={{
        sx: {
          borderRadius: BORDER_RADIUS.paper,
          backgroundImage: 'none',
        },
      }}>
      <DialogTitle fontWeight={FONT_WEIGHT.well}>
        {t(DIALOG_CONTACT_TITLE_MAPPING)}
      </DialogTitle>
      <DialogContent>{renderDialogContent()}</DialogContent>
      <DialogActions>
        <ButtonAtom onClick={handleClose}>
          {t(DIALOG_CONTACT_CLOSE_MAPPING)}
        </ButtonAtom>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDialogMolecule;
