import ConstructedCvUserPosition from '../ConstructedCvUserPosition';
import PositionResource from '../../../../../../../../../tools/resources/types/business/positions/PositionResource';
import ProcessedPositionResource from '../../../../../../../../../tools/resources/types/business/positions/processed/ProcessedPositionResource';

const createConstructedUserPositionFromResource = (
  positionResource?: PositionResource | ProcessedPositionResource,
): ConstructedCvUserPosition | undefined =>
  positionResource && {
    id: positionResource.id,
    name: positionResource.name,
    color: positionResource.color,
    specialization: positionResource.specializationId,
  };

export default createConstructedUserPositionFromResource;
