import { useEffect } from 'react';

import { UNIVERSAL_I18N } from '../../../tools/internationalization/i18n/universal/universalI18n';

const usePageTitle = (title?: string): void => {
  useEffect(() => {
    document.title = title
      ? `${title} - ${UNIVERSAL_I18N.appName}`
      : UNIVERSAL_I18N.appName;
  }, [title]);
};

export default usePageTitle;
