import setValue, { SetValue } from '../../../../../setValue';

import AppTabKey from '../../../../../../../types/app/tabs/key/AppTabKey';
import AppTabScroll from '../../../../../../../types/app/tabs/state/fields/any/scroll/AppTabScroll';

import { SET_APP_TAB_SCROLL } from '../../../../appActionTypes';

type AppTabScrollPayload = {
  key: AppTabKey;
  value: AppTabScroll;
};

export type SetAppTabScroll = SetValue<
  typeof SET_APP_TAB_SCROLL,
  AppTabScrollPayload
>;

const setAppTabScroll = (tabKey: AppTabKey, scroll: AppTabScroll) =>
  setValue<SetAppTabScroll>(SET_APP_TAB_SCROLL, { key: tabKey, value: scroll });

export default setAppTabScroll;
