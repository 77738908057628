type BorderRadius = {
  none: string;
  paper: string;
  button: string;
  chart: {
    bar: number;
  };
};

export const BORDER_RADIUS: BorderRadius = {
  none: '0px',
  paper: '16px',
  button: '16px',
  chart: {
    bar: 8,
  },
};
