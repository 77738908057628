import React from 'react';

import { Stack } from '@mui/material';

import useI18n from '../../../../hooks/internationalization/useI18n';
import useInternalNavigation from '../../../../hooks/navigation/useInternalNavigation';

import { ComponentProps } from '../../ComponentProps';
import TypographyAtom from '../../atoms/typography/TypographyAtom';
import LinkButtonAtom from '../../atoms/buttons/regular/LinkButtonAtom';

import { ORGANISMS_LOCALE_NAMESPACE } from '../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';
import {
  NOT_FOUND_BACK_MAPPING,
  NOT_FOUND_FZF_MAPPING,
  NOT_FOUND_HEADER_MAPPING,
} from '../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';
import { SPACING } from '../../../../tools/theme/app/spacing/spacing';

type NotFoundOrganismProps = ComponentProps;

const NotFoundOrganism: React.FC<NotFoundOrganismProps> = ({
  className = 'not-found-organism',
}) => {
  const { t } = useI18n(ORGANISMS_LOCALE_NAMESPACE);

  const { toActivities } = useInternalNavigation();

  return (
    <Stack
      className={`${className} not-found`}
      component='main'
      width='100%'
      direction='column'
      alignItems='center'
      spacing={SPACING.little}
      paddingX='10vw'>
      <TypographyAtom
        className='not-found__header'
        component='h5'
        align='center'
        variant='h5'>
        {t(NOT_FOUND_HEADER_MAPPING)}
      </TypographyAtom>
      <TypographyAtom
        className='not-found__fzf'
        component='h1'
        align='center'
        fontSize='clamp(800%, 15vw, 1200%)'>
        {t(NOT_FOUND_FZF_MAPPING)}
      </TypographyAtom>
      <LinkButtonAtom
        className='not_found__back-button'
        variant='contained'
        to={toActivities}
        replace>
        {t(NOT_FOUND_BACK_MAPPING)}
      </LinkButtonAtom>
    </Stack>
  );
};

export default NotFoundOrganism;
