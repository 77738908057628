import ThemedHexPaletteCommon from '../../../../../types/common/media/palette/hex/variants/ThemedHexPaletteCommon';

const CHARTS_APP_PALETTE = [
  '#ae2334',
  '#1ebc73',
  '#8fd3ff',
  '#f9c22b',
  '#ed8099',
  '#0b5e65',
  '#6b3e75',
  '#9e4539',
  '#fdcbb0',
  '#d5e04b',
  '#cd683d',
  '#f04f78',
  '#6e2727',
  '#676633',
  '#fca790',
  '#a24b6f',
  '#9babb2',
  '#a2a947',
  '#625565',
  '#ea4f36',
  '#547e64',
  '#30e1b9',
  '#484a77',
  '#e6904e',
  '#c32454',
  '#45293f',
  '#fbb954',
  '#165a4c',
  '#4d65b4',
  '#a884f3',
  '#540505',
  '#f79617',
  '#4d9be6',
  '#694f62',
  '#e83b3b',
  '#cddf6c',
  '#f57d4a',
  '#966c6c',
  '#b2ba90',
  '#b33831',
  '#239063',
  '#8ff8e2',
  '#fb6b1d',
  '#4c3e24',
  '#eaaded',
  '#f68181',
  '#323353',
  '#c7dcd0',
  '#905ea9',
  '#91db69',
  '#313638',
  '#753c54',
  '#0eaf9b',
  '#3e3546',
  '#ab947a',
  '#831c5d',
  '#0b8a8f',
  '#cf657f',
  '#374e4a',
  '#fbff86',
  '#2e222f',
  '#7f708a',
  '#92a984',
  '#7a3045',
];

export const CHARTS_APP_SUB_PALETTE: ThemedHexPaletteCommon = {
  light: CHARTS_APP_PALETTE,
  dark: CHARTS_APP_PALETTE,
};
