import setValue, { SetValue } from '../../setValue';

import ProcessedSpecializationsResource from '../../../../tools/resources/types/business/specializations/processed/ProcessedSpecializationsResource';

import { LOAD_SPECIALIZATIONS_RESOURCE_SET } from '../resourceActionTypes';

export type SetSpecializationsResource = SetValue<
  typeof LOAD_SPECIALIZATIONS_RESOURCE_SET,
  ProcessedSpecializationsResource
>;

const setSpecializationsResource = (
  resource: ProcessedSpecializationsResource,
) =>
  setValue<SetSpecializationsResource>(
    LOAD_SPECIALIZATIONS_RESOURCE_SET,
    resource,
  );

export default setSpecializationsResource;
