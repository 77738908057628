import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setAppTab from '../../../storage/actions/app/set/tabs/setAppTab';
import setPreviousAppTab from '../../../storage/actions/app/set/tabs/setPreviousAppTab';

import AppTabKey from '../../../types/app/tabs/key/AppTabKey';

interface UseAppTabsScroll {
  currentTabKey: AppTabKey;

  setCurrentTabKey(tabKey: AppTabKey): void;

  setCurrentAppTabKeyAsPrevious(): void;
}

const useAppTabs = (): UseAppTabsScroll => {
  const dispatch = useRootDispatch();
  const { tabs } = useSelector((state: RootState) => state.appReducer);

  const setCurrentTabKey = useCallback(
    (tabKey: AppTabKey) => {
      dispatch(setAppTab(tabKey));
    },
    [dispatch],
  );

  const setCurrentAppTabKeyAsPrevious = useCallback(() => {
    dispatch(setPreviousAppTab());
  }, [dispatch]);
  return {
    currentTabKey: tabs.current,
    setCurrentTabKey,
    setCurrentAppTabKeyAsPrevious,
  };
};

export default useAppTabs;
