import React from 'react';

import { Box } from '@mui/material';

import useMountEffect from '../../../hooks/wrapped/useMountEffect';
import useCvLoader from '../../../hooks/business/cv/useCvLoader';
import useCv from '../../../hooks/business/cv/useCv';
import usePageTitle from '../../../hooks/layout/page/usePageTitle';

import buildUserNameAge from '../../../tools/business/cv/build/buildUserNameAge';

import CvUniversalTemplate from '../../templates/cv/CvUniversalTemplate';

const CvPage: React.FC = () => {
  const { loadCv } = useCvLoader();
  const { cvUser } = useCv();
  usePageTitle(
    cvUser
      ? buildUserNameAge(cvUser.firstName, cvUser.middleName, cvUser.lastName)
      : undefined,
  );

  useMountEffect(() => {
    loadCv();
  });

  return (
    <Box className='cv' width='100%' height='100%'>
      <CvUniversalTemplate className='cv__universal-template' />
    </Box>
  );
};

export default CvPage;
