type Spacing = {
  none: string;
  tiny: string;
  little: string;
  small: string;
  medium: string;
  big: string;
  large: string;
  huge: string;
};

export const SPACING: Spacing = {
  none: '0px',
  tiny: '4px',
  little: '8px',
  small: '10px',
  medium: '12px',
  big: '16px',
  large: '20px',
  huge: '24px',
};
