import DemandingConstructStage from '../../../../../../../tools/constructed/stages/DemandingConstructStage';
import {
  CV_ACTIVITY_POSITION_UPDATE_CONSTRUCT_STAGE_NAME,
  CV_ACTIVITY_SPECIALIZATION_CONSTRUCT_STAGE_NAME,
} from '../CvActivitiesConstructStageName';

type CvActivityPositionUpdateConstructStage = DemandingConstructStage<
  typeof CV_ACTIVITY_POSITION_UPDATE_CONSTRUCT_STAGE_NAME,
  typeof CV_ACTIVITY_SPECIALIZATION_CONSTRUCT_STAGE_NAME
>;

export const CV_ACTIVITY_POSITION_UPDATE_CONSTRUCT_STAGE: CvActivityPositionUpdateConstructStage =
  {
    name: CV_ACTIVITY_POSITION_UPDATE_CONSTRUCT_STAGE_NAME,
    require: [CV_ACTIVITY_SPECIALIZATION_CONSTRUCT_STAGE_NAME],
  };
