import ConstructedCvActivity from '../ConstructedCvActivity';
import ConstructedCvActivityPosition from '../../fields/position/constructed/ConstructedCvActivityPosition';
import ProcessedPositionsResource from '../../../../../../../tools/resources/types/business/positions/processed/ProcessedPositionsResource';

import updateConstructedActivityPositionWithProcessedPositionsResource from '../../fields/position/constructed/operations/updateConstructedActivityPositionWithProcessedPositionsResource';

const updateConstructedActivityWithProcessedPositionResource = (
  constructedActivity: ConstructedCvActivity,
  positionsResource: ProcessedPositionsResource,
): ConstructedCvActivity => ({
  ...constructedActivity,
  position: updateConstructedActivityPositionWithProcessedPositionsResource(
    constructedActivity.position as ConstructedCvActivityPosition,
    positionsResource.find(
      (position) =>
        position.id ===
        (constructedActivity.position as ConstructedCvActivityPosition).id,
    ),
  ),
});

export default updateConstructedActivityWithProcessedPositionResource;
