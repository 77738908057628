import React from 'react';

import { Box } from '@mui/material';

import useI18n from '../../../hooks/internationalization/useI18n';
import usePageTitle from '../../../hooks/layout/page/usePageTitle';

import NotFoundUniversalTemplate from '../../templates/notFound/NotFoundUniversalTemplate';

import { ORGANISMS_LOCALE_NAMESPACE } from '../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';
import { NOT_FOUND_TITLE_MAPPING } from '../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';

const NotFoundPage: React.FC = () => {
  const { t } = useI18n(ORGANISMS_LOCALE_NAMESPACE);
  usePageTitle(t(NOT_FOUND_TITLE_MAPPING));

  return (
    <Box className='not-found' width='100%' height='100%'>
      <NotFoundUniversalTemplate className='not-found__universal-template' />
    </Box>
  );
};

export default NotFoundPage;
