import setValue, { SetValue } from '../../../setValue';

import { SET_PREVIOUS_APP_TAB } from '../../appActionTypes';

export type SetPreviousAppTab = SetValue<typeof SET_PREVIOUS_APP_TAB, null>;

const setPreviousAppTab = () =>
  setValue<SetPreviousAppTab>(SET_PREVIOUS_APP_TAB, null);

export default setPreviousAppTab;
