import SinglePeriod from '../SinglePeriod';

import compareDates from '../../date/comparators/compareDates';

const doFirstPeriodIntersectSecondPeriod = (
  a: SinglePeriod,
  b: Partial<SinglePeriod>,
) => {
  if (b.start) {
    const aStartComparedWithBStart = compareDates(a.end, b.start);
    const aEndComparedWithBStart = compareDates(a.end, b.start);

    if (aStartComparedWithBStart < 0 && aEndComparedWithBStart < 0) {
      return false;
    }
  }

  if (b.end) {
    const aStartComparedWithBEnd = compareDates(a.start, b.end);
    const aEndComparedWithBEnd = compareDates(a.end, b.end);

    if (aStartComparedWithBEnd > 0 && aEndComparedWithBEnd > 0) {
      return false;
    }
  }

  return true;
};

const doPeriodsIntersect = (
  a: Partial<SinglePeriod>,
  b: Partial<SinglePeriod>,
) => {
  if (a.start && a.end) {
    return doFirstPeriodIntersectSecondPeriod(a as SinglePeriod, b);
  }

  if (b.start && b.end) {
    return doFirstPeriodIntersectSecondPeriod(b as SinglePeriod, a);
  }

  return true;
};

export default doPeriodsIntersect;
