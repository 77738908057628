import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setSkillsAppTabListCards from '../../../storage/actions/app/set/tabs/skills/setSkillsAppTabListCards';

import SkillsAppTabListCards from '../../../types/app/tabs/state/fields/skills/cards/SkillsAppTabListCards';
import { SKILLS_APP_TAB_KEY } from '../../../types/app/tabs/key/AppTabKey';

import ExperienceGroupId from '../../../types/business/experience/group/ExperienceGroupId';

import deepClone from '../../../tools/utils/deepClone';

interface UseSkillsAppTab {
  listCards: SkillsAppTabListCards;

  switchCardMode(cardId: ExperienceGroupId): void;
}

const useSkillsAppTab = (): UseSkillsAppTab => {
  const dispatch = useRootDispatch();
  const { tabs } = useSelector((state: RootState) => state.appReducer);

  const switchCardMode = (cardId: ExperienceGroupId) => {
    const newListCards: SkillsAppTabListCards = deepClone(
      tabs[SKILLS_APP_TAB_KEY].listCards,
    );

    const listCardIndex = newListCards.indexOf(cardId);

    if (listCardIndex > -1) {
      newListCards.splice(listCardIndex, 1);
    } else {
      newListCards.push(cardId);
    }

    dispatch(setSkillsAppTabListCards(newListCards));
  };

  return {
    listCards: tabs[SKILLS_APP_TAB_KEY].listCards,
    switchCardMode,
  };
};

export default useSkillsAppTab;
