import React from 'react';

import { Box } from '@mui/material';

import useTimeDateI18n from '../../../../../hooks/internationalization/useTimeDateI18n';
import useAppThemeUtils from '../../../../../hooks/layout/theme/useAppThemeUtils';

import Duration from '../../../../../tools/timedate/duration/Duration';
import SpecializationId from '../../../../../types/business/specialization/SpecializationId';
import PositionId from '../../../../../types/business/position/PositionId';
import SpecializationName from '../../../../../types/business/specialization/SpecializationName';
import PositionName from '../../../../../types/business/position/PositionName';
import HexColorCommon from '../../../../../types/common/media/color/hex/HexColorCommon';
import NonThemedHexColorCommon from '../../../../../types/common/media/color/hex/variants/NonThemedHexColorCommon';

import isHexColorCommonThemed from '../../../../../types/common/media/color/hex/guards/isHexColorCommonThemed';

import { ComponentProps } from '../../../ComponentProps';
import ChipAtom from '../../../atoms/chips/ChipAtom';
import TooltipAtom from '../../../atoms/tooltips/TooltipAtom';
import ClickAwayListenerAtom from '../../../atoms/listeners/clickAway/ClickAwayListenerAtom';
import AvatarAtom from '../../../atoms/avatars/AvatarAtom';

type ExperienceChipMoleculeProps = ComponentProps & {
  id: SpecializationId | PositionId;
  label: SpecializationName | PositionName;
  experience: Duration;
  color: HexColorCommon;
};

const ExperienceChipMolecule: React.FC<ExperienceChipMoleculeProps> = ({
  className,
  id,
  color,
  label,
  experience,
}) => {
  const { withThemeMode } = useAppThemeUtils();
  const { dui } = useTimeDateI18n();

  const [isTooltipOpened, setIsTooltipOpened] = React.useState(false);

  const obtainNonThemedColor = (
    themedColor: HexColorCommon,
  ): NonThemedHexColorCommon => {
    if (isHexColorCommonThemed(themedColor)) {
      return withThemeMode(themedColor.light, themedColor.dark);
    } else {
      return themedColor;
    }
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpened(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpened(false);
  };

  return (
    <ClickAwayListenerAtom onClickAway={handleTooltipClose}>
      <TooltipAtom
        className='experience-chip__tooltip'
        title={dui(experience)}
        arrow
        open={isTooltipOpened}
        onClose={handleTooltipClose}
        onMouseEnter={handleTooltipOpen}
        yOffset={-16}>
        <Box
          className={`${className} experience-chip__tooltip-wrapper`}
          onClick={handleTooltipOpen}>
          <ChipAtom
            className={`${className} experience-chip`}
            id={id}
            label={label}
            labelSx={{ paddingY: 0, paddingRight: 0 }}
            variant='outlined'
            avatar={
              <AvatarAtom
                className='experience-chip__avatar'
                shape='rectangular'
                alt=' '
              />
            }
            avatarSx={{ bgcolor: obtainNonThemedColor(color) }}
            borderStyle='none'
            scale={1.25}
            sx={{ minHeight: '30px' }}
          />
        </Box>
      </TooltipAtom>
    </ClickAwayListenerAtom>
  );
};

export default ExperienceChipMolecule;
