import ConstructedCvUserPosition from '../ConstructedCvUserPosition';
import ProcessedPositionResource from '../../../../../../../../../tools/resources/types/business/positions/processed/ProcessedPositionResource';

const updateConstructedUserPositionWithProcessedPositionsResource = (
  constructedPosition: ConstructedCvUserPosition,
  positionResource?: ProcessedPositionResource,
): ConstructedCvUserPosition => {
  if (positionResource) {
    return {
      ...constructedPosition,
      color: positionResource.color,
    };
  }
  return constructedPosition;
};

export default updateConstructedUserPositionWithProcessedPositionsResource;
