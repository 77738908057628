import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Stack } from '@mui/material';

import { TemplateProps } from '../TemplateProps';
import CompactFooterOrganism from '../../components/organisms/footer/compact/CompactFooterOrganism';
import CompactHeaderOrganism from '../../components/organisms/header/compact/CompactHeaderOrganism';
import CvUserOrganism from '../../components/organisms/cv/user/CvUserOrganism';
import CvNavigationOrganism from '../../components/organisms/cv/navigation/CvNavigationOrganism';

import { SPACING } from '../../../tools/theme/app/spacing/spacing';

type CvTemplateProps = TemplateProps;

const CvUniversalTemplate: React.FC<CvTemplateProps> = ({
  className = 'cv-universal-template',
}) => (
  <Stack
    className={`${className} cv-template`}
    position='absolute'
    width='100%'
    minHeight='100%'
    direction='column'
    alignItems='center'
    spacing={SPACING.big}>
    <CompactHeaderOrganism className='cv-template__header' />
    <Box
      className='cv-template__content'
      width='100%'
      paddingX='3%'
      flexGrow={1}
      display='flex'
      flexDirection='column'
      alignItems='center'>
      <Stack
        className='cv-template__cv'
        width='100%'
        flexGrow={1}
        maxWidth='900px'
        direction='column'>
        <CvUserOrganism className='cv-template__user' />
        <CvNavigationOrganism className='cv-template__navigation' />
        <Outlet />
      </Stack>
    </Box>
    <CompactFooterOrganism className='cv-template__footer' />
  </Stack>
);

export default CvUniversalTemplate;
