export const CV_USER_BASE_BUILD_STAGE_NAME = 'CV_USER/BASE';
export const CV_USER_POSITIONS_BUILD_STAGE_NAME = 'CV_USER/POSITIONS';
export const CV_USER_TAGS_BUILD_STAGE_NAME = 'CV_USER/TAGS';
export const CV_USER_SPECIALIZATIONS_BUILD_STAGE_NAME =
  'CV_USER/SPECIALIZATIONS';
export const CV_USER_POSITIONS_UPDATE_BUILD_STAGE_NAME =
  'CV_USER/POSITIONS_UPDATE';

type CvUserConstructStageName =
  | typeof CV_USER_BASE_BUILD_STAGE_NAME
  | typeof CV_USER_POSITIONS_BUILD_STAGE_NAME
  | typeof CV_USER_TAGS_BUILD_STAGE_NAME
  | typeof CV_USER_SPECIALIZATIONS_BUILD_STAGE_NAME
  | typeof CV_USER_POSITIONS_UPDATE_BUILD_STAGE_NAME;

export default CvUserConstructStageName;
