import CompletedPosition from '../variant/CompletedPosition';

import isPosition from './isPosition';
import isSpecialization from '../../specialization/guards/isSpecialization';

const isCompletedPosition = (
  position: unknown,
): position is CompletedPosition =>
  isPosition(position) &&
  position.color !== undefined &&
  isSpecialization(position.specialization);

export default isCompletedPosition;
