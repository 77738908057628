import Resource from '../../../../types/Resource';
import LoadedResourceProcessResult from '../variants/LoadedResourceProcessResult';

const buildSuccessfulResourceProcessResult = <T extends Resource>(
  resource: T,
): LoadedResourceProcessResult<T> => ({
  success: true,
  warning: false,
  resource,
});

export default buildSuccessfulResourceProcessResult;
