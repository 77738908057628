import React from 'react';

import useDynamicResources from '../../../../../../../../../hooks/resources/useDynamicResources';

import SkillId from '../../../../../../../../../types/business/skill/SkillId';
import SkillName from '../../../../../../../../../types/business/skill/SkillName';
import SkillExperience from '../../../../../../../../../types/business/skill/SkillExperience';
import SkillAvatar from '../../../../../../../../../types/business/skill/SkillAvatar';

import { ComponentProps } from '../../../../../../../ComponentProps';
import AvatarAtom from '../../../../../../../atoms/avatars/AvatarAtom';
import ChipAtom from '../../../../../../../atoms/chips/ChipAtom';

type SkillChip = {
  id: SkillId;
  name: SkillName;
  experience: SkillExperience;
  avatar?: SkillAvatar;
};

type SkillChipYAxisLegendSkillsBarChartMoleculeProps = ComponentProps & {
  skill: SkillChip;
};

const SkillChipYAxisLegendSkillsBarChartMolecule: React.FC<
  SkillChipYAxisLegendSkillsBarChartMoleculeProps
> = ({ className, skill }) => {
  const { buildUrlFromImageResourceSource } = useDynamicResources();

  const buildLabel = (label: string): string => label;

  return (
    <ChipAtom
      className={`${className} skills-bar-chart-y-axis-skill-chip`}
      id={skill.id}
      variant='outlined'
      borderStyle='none'
      label={buildLabel(skill.name)}
      labelBreakWord={false}
      labelSx={{ paddingY: 0, paddingRight: 0, paddingLeft: 1 }}
      avatar={
        skill.avatar ? (
          <AvatarAtom
            className='skills-bar-chart-y-axis-skill-chip__avatar'
            src={buildUrlFromImageResourceSource(skill.avatar.src)}
            alt={skill.avatar.alt ?? skill.name}
            shape={skill.avatar.shape}
          />
        ) : undefined
      }
      avatarSx={{ margin: 0 }}
      scale={1.25}
    />
  );
};

export default SkillChipYAxisLegendSkillsBarChartMolecule;
