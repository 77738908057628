import React from 'react';

import { Stack } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';

import useExternalNavigation from '../../../../../hooks/navigation/useExternalNavigation';

import GeolocationPlace from '../../../../../tools/geolocation/GeolocationPlace';

import { ComponentProps } from '../../../ComponentProps';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';
import LinkAtom from '../../../atoms/links/LinkAtom';

import { FONT_WEIGHT } from '../../../../../tools/theme/app/font/fontWeight';

type GeolocationLinkMoleculeProps = ComponentProps & {
  location: GeolocationPlace;
};

const GeolocationLinkMolecule: React.FC<GeolocationLinkMoleculeProps> = ({
  className,
  location,
}) => {
  const { toGoogleMapsSearch } = useExternalNavigation();

  const renderLink = (color = 'inherit') => (
    <Stack
      className={`${className} geolocation-link-content`}
      direction='row'
      marginLeft='-3px'
      spacing='2px'
      color={color}>
      <PlaceIcon
        className='geolocation-link-content__icon'
        sx={{
          paddingTop: '6px',
          width: '20px',
          height: '24px',
        }}
      />
      <TypographyAtom
        className='geolocation-link-content__name'
        component='h6'
        variant='h6'
        fontWeight={FONT_WEIGHT.medium}>
        {location.name}
      </TypographyAtom>
    </Stack>
  );

  return location.mapping ? (
    <LinkAtom
      className={`${className} geolocation-link`}
      display='block'
      width='fit-content'
      to={toGoogleMapsSearch(location.mapping)}
      underline='hover'>
      {renderLink()}
    </LinkAtom>
  ) : (
    renderLink('text.secondary')
  );
};

export default GeolocationLinkMolecule;
