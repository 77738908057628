import React from 'react';

import { MobileDatePicker } from '@mui/x-date-pickers';

import { Moment } from 'moment';

import useTimeDateI18n from '../../../../../hooks/internationalization/useTimeDateI18n';

import Date from '../../../../../tools/timedate/date/Date';
import FixedDateFormat from '../../../../../tools/timedate/date/formats/FixedDateFormat';

import { ComponentProps } from '../../../ComponentProps';

import fromMomentToNumberDate from '../../../../../tools/timedate/date/converters/fromMomentToNumberDate';
import fromDateToMoment from '../../../../../tools/timedate/date/converters/fromDateToMoment';
import buildNowMoment from '../../../../../tools/timedate/date/builders/buildNowMoment';

type DatePickerAtomProps = ComponentProps & {
  value?: Date;
  defaultValue?: Date;
  minValue?: Date;
  maxValue?: Date;
  disableFuture?: boolean;
  format?: FixedDateFormat;
  label?: string;
  disabled?: boolean;
  sx?: object;
  onChange?: (value?: Date) => void;
};

const DatePickerAtom: React.FC<DatePickerAtomProps> = ({
  className,
  value,
  defaultValue,
  minValue,
  maxValue,
  disableFuture,
  format,
  label,
  disabled,
  sx,
  onChange,
}) => {
  const { fdf } = useTimeDateI18n();

  const onMiddlewareChange = (newValue: Moment | null) => {
    if (onChange) {
      onChange(newValue ? fromMomentToNumberDate(newValue) : undefined);
    }
  };

  const calculateMaxDate = () => {
    if (maxValue) {
      return fromDateToMoment(maxValue);
    }
    if (disableFuture) {
      return buildNowMoment();
    }
    return undefined;
  };

  return (
    <MobileDatePicker
      className={className}
      format={format ?? fdf}
      value={value === undefined ? null : fromDateToMoment(value)}
      defaultValue={defaultValue && fromDateToMoment(defaultValue)}
      minDate={minValue && fromDateToMoment(minValue)}
      maxDate={calculateMaxDate()}
      views={['year', 'month', 'day']}
      closeOnSelect
      label={label}
      disabled={disabled}
      sx={sx}
      onChange={onMiddlewareChange}
      slotProps={{
        toolbar: {
          hidden: true,
        },
        actionBar: {
          sx: { display: 'none' },
        },
      }}
    />
  );
};

export default DatePickerAtom;
