import AppThemeState from '../../types/app/theme/state/AppThemeState';
import AppTabsState from '../../types/app/tabs/state/AppTabsState';
import { DARK_THEME_MODE } from '../../types/app/theme/mode/AppThemeMode';
import { INITIAL_APP_TAB_SCROLL } from '../../types/app/tabs/state/fields/any/scroll/AppTabScroll';
import { DEFAULT_ACTIVITIES_APP_TAB_EXPANDED_CARDS } from '../../types/app/tabs/state/fields/activities/cards/ActivitiesAppTabExpandedCards';
import { DEFAULT_ACTIVITIES_APP_TAB_EXPANDED_DESCRIPTION_CARDS } from '../../types/app/tabs/state/fields/activities/cards/ActivitiesAppTabExpandedDescriptionCards';
import { DEFAULT_ACTIVITIES_APP_TAB_EXPANDED_SKILLS_CARDS } from '../../types/app/tabs/state/fields/activities/cards/ActivitiesAppTabExpandedSkillsCards';
import { DEFAULT_SKILLS_APP_TAB_LIST_CARDS } from '../../types/app/tabs/state/fields/skills/cards/SkillsAppTabListCards';
import {
  ACTIVITIES_APP_TAB_KEY,
  POSITIONS_APP_TAB_KEY,
  SKILLS_APP_TAB_KEY,
} from '../../types/app/tabs/key/AppTabKey';

import { SetAppThemeMode } from '../actions/app/set/theme/setAppThemeMode';
import { SetAppTab } from '../actions/app/set/tabs/setAppTab';
import { SetAppTabScroll } from '../actions/app/set/tabs/any/scroll/setAppTabScroll';
import { SetActivitiesAppTabExpandedCards } from '../actions/app/set/tabs/activities/setActivitiesAppTabExpandedCards';
import { SetActivitiesAppTabExpandedDescriptionCards } from '../actions/app/set/tabs/activities/setActivitiesAppTabExpandedDescriptionCards';
import { SetActivitiesAppTabExpandedSkillsCards } from '../actions/app/set/tabs/activities/setActivitiesAppTabExpandedSkillsCards';
import { SetSkillsAppTabListCardsPayload } from '../actions/app/set/tabs/skills/setSkillsAppTabListCards';
import {
  SET_ACTIVITIES_APP_TAB_EXPANDED_CARDS,
  SET_ACTIVITIES_APP_TAB_EXPANDED_DESCRIPTION_CARDS,
  SET_ACTIVITIES_APP_TAB_EXPANDED_SKILLS_CARDS,
  SET_APP_TAB,
  SET_APP_TAB_SCROLL,
  SET_APP_THEME_MODE,
  SET_PREVIOUS_APP_TAB,
  SET_SKILLS_APP_TAB_LIST_CARDS,
} from '../actions/app/appActionTypes';

import { buildAppPalette } from '../../tools/theme/app/palette/builders/buildAppPalette';
import { SetPreviousAppTab } from '../actions/app/set/tabs/setPreviousAppTab';

interface AppStore {
  theme: AppThemeState;
  tabs: AppTabsState;
}

const initialState: AppStore = {
  theme: {
    mode: DARK_THEME_MODE,
    // @ts-ignore
    palette: { palette: buildAppPalette(DARK_THEME_MODE) },
  },
  tabs: {
    current: ACTIVITIES_APP_TAB_KEY,
    previous: ACTIVITIES_APP_TAB_KEY,
    [ACTIVITIES_APP_TAB_KEY]: {
      scroll: INITIAL_APP_TAB_SCROLL,
      expandedCards: DEFAULT_ACTIVITIES_APP_TAB_EXPANDED_CARDS,
      expandedDescriptionCards:
        DEFAULT_ACTIVITIES_APP_TAB_EXPANDED_DESCRIPTION_CARDS,
      expandedSkillsCards: DEFAULT_ACTIVITIES_APP_TAB_EXPANDED_SKILLS_CARDS,
    },
    [POSITIONS_APP_TAB_KEY]: {
      scroll: INITIAL_APP_TAB_SCROLL,
    },
    [SKILLS_APP_TAB_KEY]: {
      scroll: INITIAL_APP_TAB_SCROLL,
      listCards: DEFAULT_SKILLS_APP_TAB_LIST_CARDS,
    },
  },
};

type AppReducerTypes =
  | SetAppThemeMode
  | SetAppTab
  | SetPreviousAppTab
  | SetAppTabScroll
  | SetActivitiesAppTabExpandedCards
  | SetActivitiesAppTabExpandedDescriptionCards
  | SetActivitiesAppTabExpandedSkillsCards
  | SetSkillsAppTabListCardsPayload;

export const appReducer = (
  state = initialState,
  action: AppReducerTypes,
): AppStore => {
  switch (action.type) {
    case SET_APP_THEME_MODE: {
      return {
        ...state,
        theme: {
          mode: action.payload,
          palette: buildAppPalette(action.payload),
        },
      };
    }
    case SET_APP_TAB: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          current: action.payload,
        },
      };
    }
    case SET_PREVIOUS_APP_TAB: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          previous: state.tabs.current,
        },
      };
    }
    case SET_APP_TAB_SCROLL: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.payload.key]: {
            ...state.tabs[action.payload.key],
            scroll: action.payload.value,
          },
        },
      };
    }
    case SET_ACTIVITIES_APP_TAB_EXPANDED_CARDS: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [ACTIVITIES_APP_TAB_KEY]: {
            ...state.tabs[ACTIVITIES_APP_TAB_KEY],
            expandedCards: action.payload,
          },
        },
      };
    }
    case SET_ACTIVITIES_APP_TAB_EXPANDED_DESCRIPTION_CARDS: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [ACTIVITIES_APP_TAB_KEY]: {
            ...state.tabs[ACTIVITIES_APP_TAB_KEY],
            expandedDescriptionCards: action.payload,
          },
        },
      };
    }
    case SET_ACTIVITIES_APP_TAB_EXPANDED_SKILLS_CARDS: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [ACTIVITIES_APP_TAB_KEY]: {
            ...state.tabs[ACTIVITIES_APP_TAB_KEY],
            expandedSkillsCards: action.payload,
          },
        },
      };
    }
    case SET_SKILLS_APP_TAB_LIST_CARDS: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [SKILLS_APP_TAB_KEY]: {
            ...state.tabs[SKILLS_APP_TAB_KEY],
            listCards: action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
};
