import setValue, { SetValue } from '../../../setValue';

import Constructed from '../../../../../tools/constructed/Constructed';
import CvActivitiesConstructFlow from '../../../../../types/business/cv/fields/activity/constructed/CvActivitiesConstructFlow';
import ConstructedCvActivity from '../../../../../types/business/cv/fields/activity/constructed/ConstructedCvActivity';

import { SET_INITIAL_CV_ACTIVITIES } from '../../cvActionTypes';

export type SetInitialCvActivities = SetValue<
  typeof SET_INITIAL_CV_ACTIVITIES,
  Constructed<CvActivitiesConstructFlow, ConstructedCvActivity[]>
>;

const setInitialCvActivities = (
  constructedCvActivities: Constructed<
    CvActivitiesConstructFlow,
    ConstructedCvActivity[]
  >,
) =>
  setValue<SetInitialCvActivities>(
    SET_INITIAL_CV_ACTIVITIES,
    constructedCvActivities,
  );

export default setInitialCvActivities;
