import React, { ReactElement, useEffect, useMemo } from 'react';

import { Box, Stack, useScrollTrigger } from '@mui/material';
import { Badge, Construction, Work } from '@mui/icons-material';

import useCv from '../../../../../hooks/business/cv/useCv';
import useAppTheme from '../../../../../hooks/layout/theme/useAppTheme';
import useElementStickiness from '../../../../../hooks/layout/elements/useElementStickiness';
import useI18n from '../../../../../hooks/internationalization/useI18n';
import useInternalNavigation from '../../../../../hooks/navigation/useInternalNavigation';
import useLocation from '../../../../../hooks/navigation/useLocation';
import useAppTabs from '../../../../../hooks/layout/tabs/useAppTabsKeys';
import useAppTabsScroll from '../../../../../hooks/layout/tabs/useAppTabsScroll';

import UrlPath from '../../../../../tools/web/url/paths/UrlPath';
import AppTabKey, {
  ACTIVITIES_APP_TAB_KEY,
  POSITIONS_APP_TAB_KEY,
  SKILLS_APP_TAB_KEY,
} from '../../../../../types/app/tabs/key/AppTabKey';

import { ComponentProps } from '../../../ComponentProps';
import DefaultBackgroundMolecule from '../../../molecules/background/default/DefaultBackgroundMolecule';
import FilterButtonMolecule from '../../../molecules/buttons/filter/FilterButtonMolecule';
import TabbedNavigationMolecule from '../../../molecules/navigation/tabbed/TabbedNavigationMolecule';
import LoadingBackgroundMolecule from '../../../molecules/background/loading/LoadingBackgroundMolecule';

import { ORGANISMS_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';
import {
  CV_ACTIVITIES_NAME_MAPPING,
  CV_POSITIONS_NAME_MAPPING,
  CV_SKILLS_NAME_MAPPING,
} from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';
import { BORDER_RADIUS } from '../../../../../tools/theme/app/rounding/borderRadius';
import { SPACING } from '../../../../../tools/theme/app/spacing/spacing';

type Tab = {
  key: AppTabKey;
  path: UrlPath;
  name: string;
  icon?: ReactElement;
};

type CvNavigationOrganismProps = ComponentProps;

const CvNavigationOrganism: React.FC<CvNavigationOrganismProps> = ({
  className = 'cv-navigation-organism',
}) => {
  const { appTheme } = useAppTheme();
  const { t } = useI18n(ORGANISMS_LOCALE_NAMESPACE);

  const { cvActivities } = useCv();

  const { path } = useLocation();
  const { toActivities, toSkills, toPositions, navToPath } =
    useInternalNavigation();
  const { currentTabKey, setCurrentTabKey, setCurrentAppTabKeyAsPrevious } =
    useAppTabs();

  const scrollTrigger = useScrollTrigger({ threshold: 600 });
  const { elementRef, isSticky } = useElementStickiness({
    threshold: 1,
  });
  const anchorRef = React.useRef<HTMLAnchorElement>(null);
  const { saveAppTabScroll, applyAppTabScroll, restoreAppTabScroll } =
    useAppTabsScroll(anchorRef, -160);

  const TABS: Tab[] = useMemo(
    () => [
      {
        key: ACTIVITIES_APP_TAB_KEY,
        path: toActivities.path,
        icon: <Work />,
        name: t(CV_ACTIVITIES_NAME_MAPPING),
      },
      {
        key: SKILLS_APP_TAB_KEY,
        path: toSkills.path,
        icon: <Construction />,
        name: t(CV_SKILLS_NAME_MAPPING),
      },
      {
        key: POSITIONS_APP_TAB_KEY,
        path: toPositions.path,
        icon: <Badge />,
        name: t(CV_POSITIONS_NAME_MAPPING),
      },
    ],
    [toActivities.path, toPositions.path, toSkills.path, t],
  );

  useEffect(() => {
    const newTab = TABS.find((tab) => tab.path === path);
    if (newTab && newTab.key !== currentTabKey) {
      applyAppTabScroll(newTab.key);
      setCurrentTabKey(newTab.key);
    }
  }, [TABS, path, currentTabKey, setCurrentTabKey, applyAppTabScroll]);

  const onTabChange = (newTabKey: AppTabKey) => {
    const newTab = TABS.find((tab) => tab.key === newTabKey);
    if (newTab) {
      saveAppTabScroll(currentTabKey);
      setCurrentAppTabKeyAsPrevious();
      navToPath(newTab.path);
    }
  };

  const onTabClick = (tabKey: AppTabKey) => {
    if (tabKey === currentTabKey) {
      restoreAppTabScroll();
    }
  };

  return (
    <>
      <Box className='cv-navigation__anchor' ref={anchorRef} />
      {cvActivities ? (
        <DefaultBackgroundMolecule
          className={`cv-navigation__background${isSticky ? ' sticky' : ''}`}
          ref={elementRef}
          sx={{
            position: 'sticky',
            padding: `${SPACING.big} 0 0 0`,
            marginBottom: SPACING.big,
            borderRadius: BORDER_RADIUS.paper,
            zIndex: appTheme.zIndex.appBar,
            transition: 'top 0.2s ease',
            top: {
              xs: scrollTrigger ? '-70px' : `-${SPACING.little}`,
              sm: `-${SPACING.little}`,
            },
          }}>
          <Stack
            className={`${className} cv-navigation`}
            direction='row'
            alignItems='center'
            spacing={SPACING.big}
            borderRadius={BORDER_RADIUS.paper}
            boxShadow={isSticky ? appTheme.shadows[2] : appTheme.shadows[0]}>
            <FilterButtonMolecule className='cv-navigation-organism__filter-button' />
            <TabbedNavigationMolecule
              className='cv-navigation__tabbed-navigation'
              currentTab={currentTabKey}
              tabs={TABS}
              onChange={onTabChange}
              onTabClick={onTabClick}
            />
          </Stack>
        </DefaultBackgroundMolecule>
      ) : (
        <LoadingBackgroundMolecule
          className='cv-navigation__loading'
          height='80px'
          sx={{ marginY: SPACING.big }}
        />
      )}
    </>
  );
};

export default CvNavigationOrganism;
