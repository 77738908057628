// General
export const SET_APP_THEME_MODE = 'App/Set/THEME_MODE';
export const SET_APP_TAB = 'App/Set/TAB';
export const SET_PREVIOUS_APP_TAB = 'App/Set/PREVIOUS_TAB';

// Any tabs
export const SET_APP_TAB_SCROLL = 'App/Set/Any/TAB_SCROLL';

// Activities tab
export const SET_ACTIVITIES_APP_TAB_EXPANDED_CARDS =
  'App/Set/Activities/EXPANDED_CARDS';
export const SET_ACTIVITIES_APP_TAB_EXPANDED_DESCRIPTION_CARDS =
  'App/Set/Activities/EXPANDED_DESCRIPTION_CARDS';
export const SET_ACTIVITIES_APP_TAB_EXPANDED_SKILLS_CARDS =
  'App/Set/Activities/EXPANDED_SKILLS_CARDS';

// Skills tab
export const SET_SKILLS_APP_TAB_LIST_CARDS = 'App/Set/Skills/LIST_CARDS';
