import CvActivity from '../../../../types/business/cv/fields/activity/CvActivity';
import CompletedPosition from '../../../../types/business/position/variant/CompletedPosition';

import isCompletedPosition from '../../../../types/business/position/guards/isCompletedPosition';

export type CvActivityPositionsCompleted = Omit<CvActivity, 'position'> & {
  position: CompletedPosition;
};

const validateActivityPositionsComplete = (
  activities: CvActivity[],
): activities is CvActivityPositionsCompleted[] =>
  activities.every((activity) => isCompletedPosition(activity.position));

export default validateActivityPositionsComplete;
