import setValue, { SetValue } from '../../../../setValue';

import SkillsAppTabListCards from '../../../../../../types/app/tabs/state/fields/skills/cards/SkillsAppTabListCards';

import { SET_SKILLS_APP_TAB_LIST_CARDS } from '../../../appActionTypes';

export type SetSkillsAppTabListCardsPayload = SetValue<
  typeof SET_SKILLS_APP_TAB_LIST_CARDS,
  SkillsAppTabListCards
>;

const setSkillsAppTabListCards = (listCards: SkillsAppTabListCards) =>
  setValue<SetSkillsAppTabListCardsPayload>(
    SET_SKILLS_APP_TAB_LIST_CARDS,
    listCards,
  );

export default setSkillsAppTabListCards;
