import setValue, { SetValue } from '../../setValue';

import ProjectsResource from '../../../../tools/resources/types/business/projects/ProjectsResource';

import { LOAD_PROJECTS_RESOURCE_SET } from '../resourceActionTypes';

export type SetProjectsResource = SetValue<
  typeof LOAD_PROJECTS_RESOURCE_SET,
  ProjectsResource
>;

const setProjectsResource = (resource: ProjectsResource) =>
  setValue<SetProjectsResource>(LOAD_PROJECTS_RESOURCE_SET, resource);

export default setProjectsResource;
