import React from 'react';

import { Box, Stack } from '@mui/material';

import useAppThemeUtils from '../../../../../../hooks/layout/theme/useAppThemeUtils';
import useTimeDateI18n from '../../../../../../hooks/internationalization/useTimeDateI18n';

import HexColorCommon from '../../../../../../types/common/media/color/hex/HexColorCommon';
import NonThemedHexColorCommon from '../../../../../../types/common/media/color/hex/variants/NonThemedHexColorCommon';
import PositionName from '../../../../../../types/business/position/PositionName';
import SpecializationName from '../../../../../../types/business/specialization/SpecializationName';
import { PositionSpecializationExperience } from '../../../../../../tools/business/cv/calculate/calculatePositionSpecializationsExperience';

import isHexColorCommonThemed from '../../../../../../types/common/media/color/hex/guards/isHexColorCommonThemed';

import fromMomentDurationToDuration from '../../../../../../tools/timedate/duration/converters/fromMomentDurationToDuration';
import fromDurationToMomentDuration from '../../../../../../tools/timedate/duration/converters/fromDurationToMomentDuration';

import { ComponentProps } from '../../../../ComponentProps';
import PieChartAtom from '../../../../atoms/charts/pie/PieChartAtom';
import LegendExperiencePieChartMolecule from './legend/LegendExperiencePieChartMolecule';

import { SPACING } from '../../../../../../tools/theme/app/spacing/spacing';

const EXPERIENCE_CHART_TITLE_KEY = 'label';
const EXPERIENCE_CHART_DATA_KEY = 'value';
const EXPERIENCE_CHART_COLOR_KEY = 'color';

type ExperienceSeriesEntry = {
  [EXPERIENCE_CHART_TITLE_KEY]: SpecializationName | PositionName;
  [EXPERIENCE_CHART_DATA_KEY]: number;
  [EXPERIENCE_CHART_COLOR_KEY]: NonThemedHexColorCommon;
};

const EXPERIENCE_CHART_SIZE = 280;

const EXPERIENCE_CHART_SPECIALIZATION_ID = 'SPECIALIZATION';
const EXPERIENCE_CHART_SPECIALIZATION_INNER_RADIUS = 0;
const EXPERIENCE_CHART_SPECIALIZATION_OUTER_RADIUS = 80;

const EXPERIENCE_CHART_POSITION_ID = 'POSITION';
const EXPERIENCE_CHART_POSITION_INNER_RADIUS = 104;
const EXPERIENCE_CHART_POSITION_OUTER_RADIUS = 140;

type ExperiencePieChartMoleculeProps = ComponentProps & {
  positionSpecializationsExperience: PositionSpecializationExperience[];
};

const ExperiencePieChartMolecule: React.FC<ExperiencePieChartMoleculeProps> = ({
  className,
  positionSpecializationsExperience,
}) => {
  const { withThemeMode } = useAppThemeUtils();
  const { dui } = useTimeDateI18n();

  const obtainNonThemedColor = (
    color: HexColorCommon,
  ): NonThemedHexColorCommon => {
    if (isHexColorCommonThemed(color)) {
      return withThemeMode(color.light, color.dark);
    } else {
      return color;
    }
  };

  const transformExperienceToSpecializationSeries = (
    specializationsExperience: PositionSpecializationExperience[],
  ): ExperienceSeriesEntry[] =>
    specializationsExperience.map((positionSpecializationExperience) => ({
      [EXPERIENCE_CHART_TITLE_KEY]:
        positionSpecializationExperience.specialization.name,
      [EXPERIENCE_CHART_DATA_KEY]: fromDurationToMomentDuration(
        positionSpecializationExperience.experience,
      ).asDays(),
      [EXPERIENCE_CHART_COLOR_KEY]: obtainNonThemedColor(
        positionSpecializationExperience.specialization.color,
      ),
    }));

  const transformExperienceToPositionSeries = (
    specializationsExperience: PositionSpecializationExperience[],
  ): ExperienceSeriesEntry[] =>
    specializationsExperience.flatMap((specializationExperience) =>
      specializationExperience.positionExperiences.map(
        (positionExperience) => ({
          [EXPERIENCE_CHART_TITLE_KEY]: positionExperience.position.name,
          [EXPERIENCE_CHART_DATA_KEY]: fromDurationToMomentDuration(
            positionExperience.experience,
          ).asDays(),
          [EXPERIENCE_CHART_COLOR_KEY]: obtainNonThemedColor(
            positionExperience.position.color,
          ),
        }),
      ),
    );

  const positionSpecializationExperienceValueFormatter = (
    entry: Pick<ExperienceSeriesEntry, typeof EXPERIENCE_CHART_DATA_KEY>,
  ): string =>
    dui(
      fromMomentDurationToDuration(
        fromDurationToMomentDuration({
          years: 0,
          months: 0,
          days: entry ? entry[EXPERIENCE_CHART_DATA_KEY] : 0,
        }),
      ),
    );

  const calculateSeries = (
    specializationsExperience: PositionSpecializationExperience[],
  ) => [
    {
      id: EXPERIENCE_CHART_SPECIALIZATION_ID,
      innerRadius: EXPERIENCE_CHART_SPECIALIZATION_INNER_RADIUS,
      outerRadius: EXPERIENCE_CHART_SPECIALIZATION_OUTER_RADIUS,
      data: transformExperienceToSpecializationSeries(
        specializationsExperience,
      ),
      valueFormatter: positionSpecializationExperienceValueFormatter,
    },
    {
      id: EXPERIENCE_CHART_POSITION_ID,
      innerRadius: EXPERIENCE_CHART_POSITION_INNER_RADIUS,
      outerRadius: EXPERIENCE_CHART_POSITION_OUTER_RADIUS,
      data: transformExperienceToPositionSeries(specializationsExperience),
      valueFormatter: positionSpecializationExperienceValueFormatter,
    },
  ];

  return (
    <Stack
      className={`${className} experience-pie-chart`}
      width='100%'
      direction={{ xs: 'column', sm: 'row' }}
      spacing={SPACING.huge}>
      <Box
        className='experience-pie-chart__wrapper'
        minHeight={`${EXPERIENCE_CHART_SIZE}px`}
        display='flex'
        flexGrow={1}>
        <PieChartAtom
          className={`${className} experience-pie-chart__chart`}
          series={calculateSeries(positionSpecializationsExperience)}
          legendHidden
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          width={EXPERIENCE_CHART_SIZE}
          height={EXPERIENCE_CHART_SIZE}
        />
      </Box>
      <LegendExperiencePieChartMolecule
        className='experience-pie-chart__legend'
        positionSpecializationsExperience={positionSpecializationsExperience}
      />
    </Stack>
  );
};

export default ExperiencePieChartMolecule;
