import React from 'react';

import { Button, ButtonProps } from '@mui/material';

import { BORDER_RADIUS } from '../../../../../tools/theme/app/rounding/borderRadius';
import { FONT_WEIGHT } from '../../../../../tools/theme/app/font/fontWeight';

const ButtonAtom: React.FC<ButtonProps> = ({
  className,
  id,
  component = 'button',
  type,
  variant,
  size,
  fullWidth = false,
  color,
  disabled = false,
  sx,
  startIcon,
  endIcon,
  children,
  onClick,
  onTouchStart,
}) => {
  const customSx = {
    borderRadius: BORDER_RADIUS.button,
    fontWeight: FONT_WEIGHT.well,
    transition: 'none',
    ...sx,
  };

  return (
    <Button
      className={className}
      id={id}
      component={component}
      type={type}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      color={color}
      disabled={disabled}
      disableElevation
      sx={customSx}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      onTouchStart={onTouchStart}>
      {children}
    </Button>
  );
};

export default ButtonAtom;
