import setValue, { SetValue } from '../../../../setValue';

import ActivitiesAppTabExpandedCards from '../../../../../../types/app/tabs/state/fields/activities/cards/ActivitiesAppTabExpandedCards';

import { SET_ACTIVITIES_APP_TAB_EXPANDED_CARDS } from '../../../appActionTypes';

export type SetActivitiesAppTabExpandedCards = SetValue<
  typeof SET_ACTIVITIES_APP_TAB_EXPANDED_CARDS,
  ActivitiesAppTabExpandedCards
>;

const setActivitiesAppTabExpandedCards = (
  expandedCards: ActivitiesAppTabExpandedCards,
) =>
  setValue<SetActivitiesAppTabExpandedCards>(
    SET_ACTIVITIES_APP_TAB_EXPANDED_CARDS,
    expandedCards,
  );

export default setActivitiesAppTabExpandedCards;
