import setValue, { SetValue } from '../../setValue';

import PositionsResource from '../../../../tools/resources/types/business/positions/PositionsResource';

import { LOAD_POSITIONS_RESOURCE_LOADING_SET } from '../resourceActionTypes';

export type SetLoadingPositionsResource = SetValue<
  typeof LOAD_POSITIONS_RESOURCE_LOADING_SET,
  PositionsResource
>;

const setLoadingPositionsResource = (loadingResource: PositionsResource) =>
  setValue<SetLoadingPositionsResource>(
    LOAD_POSITIONS_RESOURCE_LOADING_SET,
    loadingResource,
  );

export default setLoadingPositionsResource;
