import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setActivitiesAppTabExpandedCards from '../../../storage/actions/app/set/tabs/activities/setActivitiesAppTabExpandedCards';
import setActivitiesAppTabExpandedDescriptionCards from '../../../storage/actions/app/set/tabs/activities/setActivitiesAppTabExpandedDescriptionCards';
import setActivitiesAppTabExpandedSkillsCards from '../../../storage/actions/app/set/tabs/activities/setActivitiesAppTabExpandedSkillsCards';

import ActivitiesAppTabExpandedCards from '../../../types/app/tabs/state/fields/activities/cards/ActivitiesAppTabExpandedCards';
import ActivitiesAppTabExpandedDescriptionCards from '../../../types/app/tabs/state/fields/activities/cards/ActivitiesAppTabExpandedDescriptionCards';
import ActivitiesAppTabExpandedSkillsCards from '../../../types/app/tabs/state/fields/activities/cards/ActivitiesAppTabExpandedSkillsCards';
import { ACTIVITIES_APP_TAB_KEY } from '../../../types/app/tabs/key/AppTabKey';

import ActivityId from '../../../types/business/activity/ActivityId';

import deepClone from '../../../tools/utils/deepClone';

interface UseActivitiesAppTab {
  expandedCards: ActivitiesAppTabExpandedCards;
  expandedDescriptionCards: ActivitiesAppTabExpandedDescriptionCards;
  expandedSkillsCards: ActivitiesAppTabExpandedSkillsCards;

  switchCardExpand(cardId: ActivityId): void;

  switchCardDescriptionExpand(cardId: ActivityId): void;

  switchCardSkillsExpand(cardId: ActivityId): void;
}

const useActivitiesAppTab = (): UseActivitiesAppTab => {
  const dispatch = useRootDispatch();
  const { tabs } = useSelector((state: RootState) => state.appReducer);

  const addOrRemoveCardFromCardCollection = useCallback(
    (
      cardCollection:
        | ActivitiesAppTabExpandedCards
        | ActivitiesAppTabExpandedDescriptionCards
        | ActivitiesAppTabExpandedSkillsCards,
      card: ActivityId,
    ) => {
      const newCardCollection = deepClone(cardCollection);

      const cardIndex = newCardCollection.indexOf(card);

      if (cardIndex > -1) {
        newCardCollection.splice(cardIndex, 1);
      } else {
        newCardCollection.push(card);
      }

      return newCardCollection;
    },
    [],
  );

  const switchCardExpand = (cardId: ActivityId) => {
    const newExpandedCards = addOrRemoveCardFromCardCollection(
      tabs[ACTIVITIES_APP_TAB_KEY].expandedCards,
      cardId,
    );

    dispatch(setActivitiesAppTabExpandedCards(newExpandedCards));
  };

  const switchCardDescriptionExpand = (cardId: ActivityId) => {
    const newExpandedCards = addOrRemoveCardFromCardCollection(
      tabs[ACTIVITIES_APP_TAB_KEY].expandedDescriptionCards,
      cardId,
    );

    dispatch(setActivitiesAppTabExpandedDescriptionCards(newExpandedCards));
  };

  const switchCardSkillsExpand = (cardId: ActivityId) => {
    const newExpandedCards = addOrRemoveCardFromCardCollection(
      tabs[ACTIVITIES_APP_TAB_KEY].expandedSkillsCards,
      cardId,
    );

    dispatch(setActivitiesAppTabExpandedSkillsCards(newExpandedCards));
  };

  return {
    expandedCards: tabs[ACTIVITIES_APP_TAB_KEY].expandedCards,
    expandedDescriptionCards:
      tabs[ACTIVITIES_APP_TAB_KEY].expandedDescriptionCards,
    expandedSkillsCards: tabs[ACTIVITIES_APP_TAB_KEY].expandedSkillsCards,
    switchCardExpand,
    switchCardDescriptionExpand,
    switchCardSkillsExpand,
  };
};

export default useActivitiesAppTab;
