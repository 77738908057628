import React from 'react';

import { Box } from '@mui/material';

import CvActivity from '../../../../../../types/business/cv/fields/activity/CvActivity';

import { ComponentProps } from '../../../../ComponentProps';
import ActivityAccordionCardMolecule from '../accordion/ActivityAccordionCardMolecule';

import { BORDER_RADIUS } from '../../../../../../tools/theme/app/rounding/borderRadius';

type ActivityGroupCardMoleculeProps = ComponentProps & {
  activities: CvActivity[];
};

const ActivityGroupCardMolecule: React.FC<ActivityGroupCardMoleculeProps> = ({
  className,
  activities,
}) => {
  const customSx = {
    '& .activity-group-card': {
      '&:first-of-type': {
        borderTopLeftRadius: BORDER_RADIUS.paper,
        borderTopRightRadius: BORDER_RADIUS.paper,
      },
      '&:last-of-type': {
        borderBottomRightRadius: BORDER_RADIUS.paper,
        borderBottomLeftRadius: BORDER_RADIUS.paper,
      },
    },
  };

  return (
    <Box className={`${className} activity-group-card`} sx={customSx}>
      {activities.map((activity) => (
        <ActivityAccordionCardMolecule
          className={`activity-group-card activity-group-card__entry-${activity.id}`}
          key={activity.id}
          activity={activity}
        />
      ))}
    </Box>
  );
};

export default ActivityGroupCardMolecule;
