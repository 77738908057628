import setValue, { SetValue } from '../../setValue';

import UserResource from '../../../../tools/resources/types/business/users/UserResource';

import { LOAD_USER_RESOURCE_SET } from '../resourceActionTypes';

export type SetUserResource = SetValue<
  typeof LOAD_USER_RESOURCE_SET,
  UserResource
>;

const setUserResource = (resource: UserResource) =>
  setValue<SetUserResource>(LOAD_USER_RESOURCE_SET, resource);

export default setUserResource;
