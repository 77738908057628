import React, { useMemo } from 'react';

import { Accordion } from '@mui/material';

import useActivitiesAppTab from '../../../../../../hooks/layout/tabs/useActivitiesAppTab';

import CvActivity from '../../../../../../types/business/cv/fields/activity/CvActivity';
import { ComponentProps } from '../../../../ComponentProps';
import SummaryOfActivityAccordionCardMolecule from './parts/SummaryOfActivityAccordionCardMolecule';
import DetailsOfActivityAccordionCardMolecule from './parts/DetailsOfActivityAccordionCardMolecule';

import { SPACING } from '../../../../../../tools/theme/app/spacing/spacing';

type ActivityAccordionCardMoleculeProps = ComponentProps & {
  activity: CvActivity;
};

const ActivityAccordionCardMolecule: React.FC<
  ActivityAccordionCardMoleculeProps
> = ({ className, activity }) => {
  const {
    expandedCards,
    expandedDescriptionCards,
    expandedSkillsCards,
    switchCardExpand,
    switchCardDescriptionExpand,
    switchCardSkillsExpand,
  } = useActivitiesAppTab();

  const isCardExpanded = useMemo(
    () => expandedCards.includes(activity.id),
    [activity.id, expandedCards],
  );

  const isCardDescriptionExpanded = useMemo(
    () => expandedDescriptionCards.includes(activity.id),
    [activity.id, expandedDescriptionCards],
  );

  const isCardSkillsExpanded = useMemo(
    () => expandedSkillsCards.includes(activity.id),
    [activity.id, expandedSkillsCards],
  );

  return (
    <Accordion
      className={`${className} activity-accordion-card`}
      key={activity.id}
      expanded={isCardExpanded}
      onChange={(_, __) => switchCardExpand(activity.id)}
      elevation={0}
      sx={{ width: '100%', padding: SPACING.huge }}>
      <SummaryOfActivityAccordionCardMolecule
        className='activity-accordion-card__summary'
        activity={activity}
        expanded={isCardExpanded}
        onExpand={() => switchCardExpand(activity.id)}
      />
      <DetailsOfActivityAccordionCardMolecule
        className='activity-accordion-card__details'
        activity={activity}
        isDescriptionExpanded={isCardDescriptionExpanded}
        isSkillsExpanded={isCardSkillsExpanded}
        onDescriptionExpand={() => switchCardDescriptionExpand(activity.id)}
        onSkillsExpand={() => switchCardSkillsExpand(activity.id)}
      />
    </Accordion>
  );
};

export default ActivityAccordionCardMolecule;
