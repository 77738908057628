import setValue, { SetValue } from '../../../../setValue';

import ActivitiesAppTabExpandedSkillsCards from '../../../../../../types/app/tabs/state/fields/activities/cards/ActivitiesAppTabExpandedSkillsCards';

import { SET_ACTIVITIES_APP_TAB_EXPANDED_SKILLS_CARDS } from '../../../appActionTypes';

export type SetActivitiesAppTabExpandedSkillsCards = SetValue<
  typeof SET_ACTIVITIES_APP_TAB_EXPANDED_SKILLS_CARDS,
  ActivitiesAppTabExpandedSkillsCards
>;

const setActivitiesAppTabExpandedSkillsCards = (
  expandedSkillsCards: ActivitiesAppTabExpandedSkillsCards,
) =>
  setValue<SetActivitiesAppTabExpandedSkillsCards>(
    SET_ACTIVITIES_APP_TAB_EXPANDED_SKILLS_CARDS,
    expandedSkillsCards,
  );

export default setActivitiesAppTabExpandedSkillsCards;
