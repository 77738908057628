import React from 'react';

import { Box, Stack } from '@mui/material';

import PositionId from '../../../../../../../types/business/position/PositionId';
import PositionName from '../../../../../../../types/business/position/PositionName';
import SpecializationId from '../../../../../../../types/business/specialization/SpecializationId';
import SpecializationName from '../../../../../../../types/business/specialization/SpecializationName';
import Duration from '../../../../../../../tools/timedate/duration/Duration';
import HexColorCommon from '../../../../../../../types/common/media/color/hex/HexColorCommon';

import { ComponentProps } from '../../../../../ComponentProps';
import ExperienceChipMolecule from '../../../../chips/experience/ExperienceChipMolecule';

import { SPACING } from '../../../../../../../tools/theme/app/spacing/spacing';

export type SpecializationOrPositionExperience = {
  id: SpecializationId | PositionId;
  label: SpecializationName | PositionName;
  experience: Duration;
  color: HexColorCommon;
};

type ExperienceListLegendExperiencePieChartMoleculeProps = ComponentProps & {
  specializationOrPositionExperiences: SpecializationOrPositionExperience[];
};

const ExperienceListLegendExperiencePieChartMolecule: React.FC<
  ExperienceListLegendExperiencePieChartMoleculeProps
> = ({ className, specializationOrPositionExperiences }) => {
  const renderChip = (
    id: SpecializationId | PositionId,
    label: SpecializationName | PositionName,
    experience: Duration,
    color: HexColorCommon,
  ) => (
    <Box
      className='experience-list__item'
      component='li'
      width='fit-content'
      height='fit-content'
      key={id}
      sx={{ listStyle: 'none' }}>
      <ExperienceChipMolecule
        id={id}
        label={label}
        experience={experience}
        color={color}
      />
    </Box>
  );

  const renderChips = (experiences: SpecializationOrPositionExperience[]) =>
    experiences.map((experience) =>
      renderChip(
        experience.id,
        experience.label,
        experience.experience,
        experience.color,
      ),
    );

  return (
    <Stack
      className={`${className} experience-pie-chart-legend-list`}
      component='ul'
      width='100%'
      height='fit-content'
      padding={SPACING.none}
      margin={SPACING.none}
      direction='row'
      spacing={SPACING.big}
      useFlexGap
      flexWrap='wrap'>
      {renderChips(specializationOrPositionExperiences)}
    </Stack>
  );
};

export default ExperienceListLegendExperiencePieChartMolecule;
