import React from 'react';

import { ComponentProps } from '../../../ComponentProps';
import ButtonAtom from '../../../atoms/buttons/regular/ButtonAtom';

type MoreButtonMoleculeProps = ComponentProps & {
  display: boolean;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  children: React.ReactNode;
};

const MoreButtonMolecule: React.FC<MoreButtonMoleculeProps> = ({
  className,
  display,
  size,
  onClick,
  children,
}) => {
  const calculateTextScaleMultiplier = () => {
    switch (size) {
      case 'large':
        return 1.5;
      case 'medium':
        return 1.25;
      case 'small':
      default:
        return 1;
    }
  };

  return (
    <ButtonAtom
      className={`${className} more-button`}
      variant='contained'
      size={size}
      sx={{
        display: display ? undefined : 'none',
        fontSize: `${calculateTextScaleMultiplier() * 0.875}rem`,
      }}
      onClick={onClick}>
      {children}
    </ButtonAtom>
  );
};

export default MoreButtonMolecule;
