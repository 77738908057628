import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button, ButtonProps } from '@mui/material';

import buildUrlFromEndpoint from '../../../../../tools/navigation/utils/buildUrlFromEndpoint';
import ExternalNavEndpoint from '../../../../../tools/navigation/endpoints/ExternalNavEndpoint';
import InternalNavEndpoint from '../../../../../tools/navigation/endpoints/InternalNavEndpoint';

import { BORDER_RADIUS } from '../../../../../tools/theme/app/rounding/borderRadius';

export type LinkButtonAtomProps = ButtonProps & {
  to: ExternalNavEndpoint | InternalNavEndpoint;
  replace?: boolean;
};

const LinkButtonAtom: React.FC<LinkButtonAtomProps> = ({
  className,
  id,
  variant,
  children,
  to,
  replace = false,
  sx,
}) => {
  const customSx = {
    borderRadius: BORDER_RADIUS.button,
    transition: 'none',
    ...sx,
  };

  return (
    <Button
      className={className}
      id={id}
      variant={variant}
      disableElevation
      component={RouterLink}
      replace={replace}
      to={buildUrlFromEndpoint(to)}
      sx={customSx}>
      {children}
    </Button>
  );
};

export default LinkButtonAtom;
