export const DATE_DEFAULT_YEAR = 0;
export const DATE_DEFAULT_MONTH = 1;
export const DATE_DEFAULT_DAY = 1;

type NumberDate = {
  year: number;
  month: number;
  day: number;
};

export default NumberDate;
