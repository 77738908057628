import SinglePeriod from '../SinglePeriod';
import NumberDate from '../../date/NumberDate';

import buildNumberDate from '../../date/builders/buildNumberDate';

import { PRESENT_DATE } from '../../date/PresentDate';

const buildPeriodFromToNow = (from?: NumberDate): SinglePeriod => ({
  start: buildNumberDate(from?.year, from?.month, from?.day),
  end: PRESENT_DATE,
});

export default buildPeriodFromToNow;
