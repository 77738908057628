import PositionsResource from '../../../types/business/positions/PositionsResource';
import ProcessedPositionsResource from '../../../types/business/positions/processed/ProcessedPositionsResource';
import SpecializationsResource from '../../../types/business/specializations/SpecializationsResource';
import ThemedHexPaletteCommon from '../../../../../types/common/media/palette/hex/variants/ThemedHexPaletteCommon';

import ResourceProcessResult from '../../types/result/ResourceProcessResult';
import buildSuccessfulResourceProcessResult from '../../types/result/builders/buildSuccessfulResourceProcessResult';
import buildWarningResourceProcessResult from '../../types/result/builders/buildWarningResourceProcessResult';

import processResourceWithPalette from '../../common/processResourceWithPalette';

const processPositionsResourceWithPalette = (
  positionsResource: PositionsResource,
  specializationsResource: SpecializationsResource | null,
  palette: ThemedHexPaletteCommon,
): ResourceProcessResult<PositionsResource | ProcessedPositionsResource> => {
  if (specializationsResource) {
    let numberOfColoredSpecializations = 0;
    specializationsResource.forEach((specialization) => {
      if (specialization.color) {
        numberOfColoredSpecializations += 1;
      }
    });
    const numberOfUsedColorsBySpecializations =
      specializationsResource.length - numberOfColoredSpecializations;

    let numberOfRequiredColorsByPositions = 0;
    positionsResource.forEach((position) => {
      if (!position.color) {
        numberOfRequiredColorsByPositions += 1;
      }
    });

    // The entire palette is used by default in case if there are not enough colors in the palette for the positions
    const positionsPalette: ThemedHexPaletteCommon = {
      light: palette.light,
      dark: palette.dark,
    };

    const numberOfUnusedLightPaletteColors =
      palette.light.length - numberOfUsedColorsBySpecializations;
    const numberOfUnusedDarkPaletteColors =
      palette.dark.length - numberOfUsedColorsBySpecializations;

    // If there are enough colors in the palette for the positions, then the used colors will be removed
    if (numberOfUnusedLightPaletteColors >= numberOfRequiredColorsByPositions) {
      positionsPalette.light = positionsPalette.light.slice(
        numberOfUsedColorsBySpecializations,
      );
    }
    if (numberOfUnusedDarkPaletteColors >= numberOfRequiredColorsByPositions) {
      positionsPalette.dark = positionsPalette.dark.slice(
        numberOfUsedColorsBySpecializations,
      );
    }

    return buildSuccessfulResourceProcessResult<ProcessedPositionsResource>(
      processResourceWithPalette(positionsResource, positionsPalette),
    );
  } else {
    return buildWarningResourceProcessResult<PositionsResource>(
      positionsResource,
    );
  }
};

export default processPositionsResourceWithPalette;
