import React from 'react';

import { Grid } from '@mui/material';

import CvUser from '../../../../../../types/business/cv/fields/user/CvUser';
import UserAge from '../../../../../../types/business/user/UserAge';

import calculateDuration from '../../../../../../tools/timedate/duration/utils/calculateDuration';
import buildPeriodFromToNow from '../../../../../../tools/timedate/period/builders/buildPeriodFromToNow';
import buildNumberDate from '../../../../../../tools/timedate/date/builders/buildNumberDate';
import buildUserNameAge from '../../../../../../tools/business/cv/build/buildUserNameAge';

import { ComponentProps } from '../../../../ComponentProps';
import TypographyAtom from '../../../../atoms/typography/TypographyAtom';
import GeolocationLinkMolecule from '../../../links/geolocation/GeolocationLinkMolecule';

import { SPACING } from '../../../../../../tools/theme/app/spacing/spacing';

type UserInformationGridMoleculeProps = ComponentProps & {
  user: CvUser;
};

const UserInformationGridMolecule: React.FC<
  UserInformationGridMoleculeProps
> = ({ className, user }) => {
  const calculateUserAge = (): UserAge | undefined => {
    if (user.birthdate) {
      return calculateDuration(
        buildPeriodFromToNow(
          buildNumberDate(
            user.birthdate.year,
            user.birthdate?.month,
            user.birthdate?.day,
          ),
        ),
      ).years;
    }
    return user.age;
  };

  const renderNameAge = () => {
    const calculatedAge = calculateUserAge();

    return (
      <Grid item>
        <TypographyAtom
          className='user-information__name-age'
          component='h4'
          variant='h4'
          fontSize='1.75rem'>
          {buildUserNameAge(
            user.firstName,
            user.middleName,
            user.lastName,
            calculatedAge,
          )}
        </TypographyAtom>
      </Grid>
    );
  };

  const renderPosition = () => {
    if (user.position) {
      return (
        <Grid item>
          <TypographyAtom
            className='user-information__position'
            component='h5'
            variant='h5'>
            {user.position.name}
          </TypographyAtom>
        </Grid>
      );
    }
  };

  const renderLocation = () => {
    if (user.location) {
      return (
        <Grid item>
          <GeolocationLinkMolecule
            className='user-information__location-link'
            location={user.location}
          />
        </Grid>
      );
    }
  };

  return (
    <Grid
      className={`${className} user-information`}
      item
      container
      direction='column'
      rowSpacing={SPACING.tiny}>
      {renderNameAge()}
      {renderPosition()}
      {renderLocation()}
    </Grid>
  );
};

export default UserInformationGridMolecule;
