import React from 'react';

import { Box, Stack } from '@mui/material';

import SkillId from '../../../../../types/business/skill/SkillId';
import SkillName from '../../../../../types/business/skill/SkillName';
import SkillExperience from '../../../../../types/business/skill/SkillExperience';
import SkillAvatar from '../../../../../types/business/skill/SkillAvatar';

import { ComponentProps } from '../../../ComponentProps';
import SkillChipMolecule from '../../chips/skill/SkillChipMolecule';
import MoreButtonMolecule from '../../buttons/more/MoreButtonMolecule';

import { SPACING } from '../../../../../tools/theme/app/spacing/spacing';

type SkillListMoleculeItem = {
  id: SkillId;
  name: SkillName;
  experience?: SkillExperience;
  avatar?: SkillAvatar;
};

type SkillListMoleculeProps = ComponentProps & {
  skills: SkillListMoleculeItem[];
  isExpanded?: boolean;
  maxSkills?: number;
  size?: 'small' | 'medium' | 'large';
  onExpand?: () => void;
  sx?: object;
};

const SkillListMolecule: React.FC<SkillListMoleculeProps> = ({
  className,
  skills,
  isExpanded = false,
  maxSkills = 4,
  size = 'medium',
  onExpand,
  sx,
}) => {
  const [expanded, setExpanded] = React.useState(isExpanded);

  const handleExpand = () => {
    setExpanded(true);
    onExpand?.();
  };

  const renderChips = () =>
    skills.slice(0, expanded ? skills.length : maxSkills).map((skill) => (
      <Box
        className='skill-list__item'
        component='li'
        key={skill.id}
        sx={{ listStyle: 'none' }}>
        <SkillChipMolecule
          className='skill-list__item-chip'
          skill={{
            id: skill.id,
            name: skill.name,
            experience: skill.experience,
            avatar: skill.avatar,
          }}
          size={size}
        />
      </Box>
    ));

  const calculateSpacing = () => {
    switch (size) {
      case 'large':
        return SPACING.medium;
      case 'medium':
        return SPACING.small;
      case 'small':
      default:
        return SPACING.little;
    }
  };

  return (
    <Stack
      className={`${className} skill-list`}
      width='100%'
      component='ul'
      padding={SPACING.none}
      margin={SPACING.none}
      spacing={calculateSpacing()}
      direction='row'
      useFlexGap
      flexWrap='wrap'
      sx={sx}>
      {renderChips()}
      <MoreButtonMolecule
        className='skill-list__more-button'
        display={!expanded && skills.length > maxSkills}
        size={size}
        onClick={() => handleExpand()}>
        {`+${skills.length - maxSkills}`}
      </MoreButtonMolecule>
    </Stack>
  );
};

export default SkillListMolecule;
