import React from 'react';

import { Box, Stack } from '@mui/material';

import useAppThemeUtils from '../../../../../hooks/layout/theme/useAppThemeUtils';
import useAssetsResources from '../../../../../hooks/resources/useAssetsResources';
import useWindowBreakpoint from '../../../../../hooks/layout/window/useWindowBreakpoint';

import { ComponentProps } from '../../../ComponentProps';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';
import AvatarAtom from '../../../atoms/avatars/AvatarAtom';

import { SPACING } from '../../../../../tools/theme/app/spacing/spacing';
import { FONT_WEIGHT } from '../../../../../tools/theme/app/font/fontWeight';

type EmptyPlaceholderMoleculeProps = ComponentProps & {
  text?: string;
  width?: string;
  height?: string;
  minHeight?: string;
};

const EmptyPlaceholderMolecule: React.FC<EmptyPlaceholderMoleculeProps> = ({
  className,
  text,
  width,
  height,
  minHeight,
}) => {
  const { withThemeMode } = useAppThemeUtils();
  const { emptyBoxLogo } = useAssetsResources();
  const breakpoint = useWindowBreakpoint();

  const calculateImageSize = () => {
    switch (breakpoint) {
      case 'xs':
        return 6;
      case 'sm':
        return 7;
      default:
        return 8;
    }
  };

  return (
    <Box
      className={`${className} empty-placeholder`}
      display='flex'
      alignItems='center'
      justifyContent='center'
      width={width}
      height={height}
      minHeight={minHeight}>
      <Stack
        className='empty-placeholder__content'
        direction='column'
        alignItems='center'
        spacing={SPACING.little}>
        <AvatarAtom
          className='empty-placeholder-image'
          src={withThemeMode(emptyBoxLogo.src.light, emptyBoxLogo.src.dark)}
          alt={emptyBoxLogo.alt}
          shape={emptyBoxLogo.shape}
          sx={{
            width: `${calculateImageSize() * 10}px`,
            height: `${calculateImageSize() * 6}px`,
          }}
        />
        {text ? (
          <TypographyAtom
            className='empty-placeholder__text'
            component='h6'
            variant='h6'
            width='80%'
            align='center'
            fontWeight={FONT_WEIGHT.medium}>
            {text}
          </TypographyAtom>
        ) : null}
      </Stack>
    </Box>
  );
};

export default EmptyPlaceholderMolecule;
