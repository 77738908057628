import React from 'react';

import { Paper } from '@mui/material';

import { ComponentProps } from '../../../ComponentProps';

import { BORDER_RADIUS } from '../../../../../tools/theme/app/rounding/borderRadius';
import { SPACING } from '../../../../../tools/theme/app/spacing/spacing';

type BackgroundPaperMoleculeProps = ComponentProps & {
  component?: React.ElementType;
  square?: boolean;
  sx?: object;
  children?: React.ReactNode;
};

const PaperBackgroundMolecule: React.FC<BackgroundPaperMoleculeProps> = ({
  className,
  component = 'div',
  square = false,
  sx,
  children,
}) => (
  <Paper
    className={`${className} paper-background`}
    component={component}
    sx={{
      width: '100%',
      height: 'fit-content',
      padding: SPACING.huge,
      borderRadius: square ? BORDER_RADIUS.none : BORDER_RADIUS.paper,
      ...sx,
    }}
    elevation={0}>
    {children}
  </Paper>
);

export default PaperBackgroundMolecule;
