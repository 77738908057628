import DemandingConstructStage from '../../../../../../../tools/constructed/stages/DemandingConstructStage';
import {
  CV_USER_POSITIONS_UPDATE_BUILD_STAGE_NAME,
  CV_USER_SPECIALIZATIONS_BUILD_STAGE_NAME,
} from '../CvUserConstructStageName';

type CvUserPositionUpdateConstructStage = DemandingConstructStage<
  typeof CV_USER_POSITIONS_UPDATE_BUILD_STAGE_NAME,
  typeof CV_USER_SPECIALIZATIONS_BUILD_STAGE_NAME
>;

export const CV_USER_POSITION_UPDATE_CONSTRUCT_STAGE: CvUserPositionUpdateConstructStage =
  {
    name: CV_USER_POSITIONS_UPDATE_BUILD_STAGE_NAME,
    require: [CV_USER_SPECIALIZATIONS_BUILD_STAGE_NAME],
  };
